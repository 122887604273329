import {apis, cookies} from '../constants-enumerate';
import Cookies from 'js-cookie';
import {getDeviceId} from '../device';

const academyMarketingRequest = new FormData();
const savedDeviceId = getDeviceId();
const savedToken = Cookies.get(cookies.TOKEN);

if (savedToken) academyMarketingRequest.append('token', savedToken);
if (savedDeviceId) academyMarketingRequest.append('device_id', savedDeviceId);

const apiRootUrl = location.hostname.includes('qat') ? 'https://dev.adventureacademy.com' : '';

const resourceEnumerateConfig = {
  apis: {
    [apis.MARKETING]: {
      url: `${apiRootUrl}/ws/academy_marketing/0.1/json/Resource/Enumerate/init`,
      requestOptions: {
        cache: 'no-cache',
        method: 'POST',
        credentials: 'include',
        body: academyMarketingRequest
      },
      developmentVariables() { // return an object that is passed into the develpomentConfig function
        return {
          host: apiRootUrl
        };
      },
      stageVariables() { // return an object that is passed into the stageConfig function
        return {
          host: apiRootUrl
        };
      },
      invalidateCache() { // This function is invoked before each get() call and it controls whether or not a cached version is returned
        return false;
      }
    }
  },
  developmentConfig: () => import('./dev-api-config'), // lazy-load dev config
  stageConfig: () => import('./stage-api-config') // lazy-load stage config
};

export default resourceEnumerateConfig;

