import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {accessibilityMixin} from '../accessibility-mixin';
import {clickTrackerMixin} from '../click-tracker-mixin';

/**
 * @summary MainFooter
 * @extends {AoflElement}
 */
@customElement('main-footer')
class MainFooter extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * Creates an instance of MainFooter.
   */
  constructor() {
    super();
    this.currentYear = (new Date()).getFullYear();
  }

  /**
   * @readonly
   */
  static is = 'main-footer';

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default MainFooter;
