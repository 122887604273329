import {SimpleModals} from './simple-modal';
import {layoutSdo} from './sdo-layout';
import {marketingTrackerQueue} from './marketing-tracker-queue';
import * as Sentry from '@sentry/browser';

const skipModalEvent = ['loaderIcon'];

/**
 *
 * @class ModalService
 */
class ModalService {
  /**
   *
   * @static
   * @param {Object} modalsConfig
   */
  static addModals(modalsConfig) {
    SimpleModals.addModals(modalsConfig);
  }

  /**
   *
   * @static
   * @param {String} modalName
   */
  static show(modalName, scrollOptions = {}) {
    Sentry.addBreadcrumb({
      category: 'ui.modal-show',
      data: {
        name: modalName
      },
      level: 'info'
    });
    if (!skipModalEvent.includes(modalName)) {
      marketingTrackerQueue.enqueueEvent({
        url: '@adventureAcademyPopupShow',
        kvp: {
          popup: modalName
        }
      });
    }
    const payload = {
      top: scrollOptions?.top ?? window.pageYOffset,
      left: scrollOptions?.left ?? window.pageXOffset,
      freeze: true,
      scrollToTop: scrollOptions?.scrollToTop ?? false
    };
    layoutSdo.scroll = payload;
    layoutSdo.overlay = true;
    SimpleModals.show(modalName);
    document.activeElement.blur();
  }

  /**
   *
   * @static
   */
  static hide() {
    SimpleModals.hide();
    layoutSdo.overlay = false;
    layoutSdo.resetScroll();
    Sentry.addBreadcrumb({
      category: 'ui.modal-hide',
      level: 'info'
    });
  }
}

export {
  ModalService
};

