import {ApiRequest} from '@aofl/api-request';
import {apiFormatters, apis, cacheNamespaces, environment, localStorageKeys} from './constants-enumerate';
import MarketingJsonFormatter from './__config/marketing-json-formatter';
import {resourceEnumerate} from './resource-enumerate';

const apiRequest = new ApiRequest();
apiRequest.addFormatter(apiFormatters.MARKETING_JSON, MarketingJsonFormatter);

const apiRootUrl = location.hostname.includes('qat') ? 'https://dev.adventureacademy.com' : '';

/**
 *
 * Please keep all api endpoints in alphabetical order, with the exception of request()
 */
class ApiService {
  /**
   *
   * @param {*} apiEndpointKey
   * @param {*} payload
   * @param {*} format
   * @param {*} fromCache
   * @param {*} namespace
   */
  static async request(apiEndpointKey, payload, format = apiFormatters.MARKETING_JSON,
  fromCache = true, namespace = 'default') {
    const resources = await resourceEnumerate.get(apis.MARKETING);
    const url = `${apiRootUrl}/ws/academy_marketing/0.1/json` + resources.payload.api_endpoints[apiEndpointKey];

    return apiRequest.request(url, payload, format, fromCache, namespace);
  }


  /**
   * Login to be called for Sams Club flow.
   *
   * @param {String} username
   * @param {String} password
   * @return {Promise}
   */
  static accountServiceLogin(username, password) {
    const request = {
      args: [{username, password, platform: environment.PLATFORM}]
    };

    apiRequest.getCacheManager(cacheNamespaces.API_AUTH).clear();
    return this.request('accountservice_login', request, apiFormatters.MARKETING_JSON, false, cacheNamespaces.API_AUTH);
  }


  /**
   * Required in order to pass the link_token from Sams Club.
   *
   * @param {String} token (the login response token)
   * @param {String} link_token (the link token from sams club, only for bundles)
   * @return {Promise}
   */
  static acceptLinkRequest(token, link_token) { // eslint-disable-line
    const request = {
      args: [{token, link_token, platform: environment.PLATFORM}] // eslint-disable-line
    };

    return this.request('accountservice_acceptlinkrequest', request, apiFormatters.MARKETING_JSON, false, cacheNamespaces.API_AUTH);
  }

  /**
   * Creates customer support ticket
   *
   * @static
   * @param {*} name
   * @param {*} email_address
   * @param {*} message
   * @return {Promise}
   */
  static createTicket(name, email_address, message) { // eslint-disable-line
    const request = {
      args: [
        {
          name,
          email_address, // eslint-disable-line
          message
        }
      ]
    };

    return this.request('customersupport_createticket', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * @static
   * @param {String} email
   * @return {Promise}
   */
  static emailIsActiveUser(email) {
    const request = {
      args: [
        {
          email
        }
      ]
    };

    return this.request('email_isactiveuser', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Unsubscribes user from the email list.
   *
   * @param {String} id Postup id
   * @param {String} email
   * @return {Promise}
   */
  static emailUnsubscribe(id, email) {
    const request = {
      args: [
        {
          'email_address': email,
          'recipient_id': id
        }
      ]
    };

    return this.request('email_unsubscribe', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Calls event_log endpoint to record BI tracking data.
   *
   * @param {Array} payload
   * @return {Promise}
   */
  static eventLog(payload) {
    const request = {
      args: [payload]
    };

    return this.request('event_log', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Fetch the survey data
   * @param {String} survey_uuid
   * @return {Promise}
   */
  static fetchSurveyData(payload) { // eslint-disable-line
    const request = {
      args: [payload] // eslint-disable-line
    };

    return this.request('survey_get', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Fetch the survey uuid
   * @return {Promise}
   */
  static fetchSurveyUuid(survey_location_key) { // eslint-disable-line
    const request = {
      args: [{survey_location_key}] // eslint-disable-line
    };
    return this.request('survey_get_uuid', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   *
   * @param {String} email_address
   * @return {Promise}
   */
  static forgotPassword(email_address) { // eslint-disable-line
    const request = {
      args: [{email_address}] // eslint-disable-line
    };

    return this.request('user_requestcredentialrecovery', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   *
   * Retrieves the pixel for the given campaign and event
   * @static
   * @param {String} event
   * @return {Promise}
   */
  static resolvePixelInfo(event, domain = '') {
    const request = {
      args: [
        {
          event,
          domain
        }
      ]
    };

    return this.request('campaign_resolvepixelinfo', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   *
   * Retrieves the product from a third party receipt
   * @static
   * @param {Object} payload
   * @param {String} payload.receipt
   * @param {String} payload.paymentType,
   * @return {Promise}
   */
  static getProductFromThirdPartyReceipt(payload) {
    const request = {
      args: [
        {
        receipt: payload.receipt, // eslint-disable-line
        payment_type: payload.paymentType // eslint-disable-line
        }
      ]
    };

    return this.request('product_resolveproductforthirdpartyreceipt', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * @static
   * @param {String} productGroupHash
   * @return {Promise}
   */
  static getProductInfo(productGroupHash) {
    const request = {
      args: [
        {
          product_group_hash: productGroupHash // eslint-disable-line
        }
      ]
    };

    return this.request('product_getinfo', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * @static
   * @param {String} productGroupHash
   * @return {Promise}
   */
  static getProductGroupInfo(productGroupHash) {
    const request = {
      args: [
        {
          product_group_hash: productGroupHash // eslint-disable-line
        }
      ]
    };

    return this.request('product_get_groupinfo', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   *
   * @param {Object} payload
   * @param {String} payload.productHash
   * @return {Promise}
   */
  static getSubscriberStatus(payload) {
    const request = {
      args: [
        {
          email: payload
        }
      ]
    };
    return this.request('prospect_getsubscriberstatus', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * @static
   * @param {String} productGroupHash
   * @return {Promise}
   */
  static isUpgradeEligible(productGroupHash) {
    const request = {
      args: [
        {
          product_group_hash: productGroupHash // eslint-disable-line
        }
      ]
    };

    return this.request('subscriber_isupgradeeligible', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * @static
   * @param {String} productGroupHash
   * @return {Promise}
   */
  static getStandaloneUpgradeOfferInfo(productGroupHash) {
    const request = {
      args: [
        {
          product_group_hash: productGroupHash // eslint-disable-line
        }
      ]
    };

    return this.request('subscriber_getspecialofferupgradeinfo', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * @static
   * @param {String} productGroupHash
   * @return {Promise}
   */
  static getExtendAccountInfo(productGroupHash) {
    const request = {
      args: [
        {
          product_group_hash: productGroupHash // eslint-disable-line
        }
      ]
    };

    return this.request('subscriber_getspecialofferextensioninfo', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Try to login in with provided username and password
   *
   * @param {String} username
   * @param {String} password
   * @return {Promise}
   */
  static login(username, password) {
    const request = {
      args: [{username, password, platform: environment.PLATFORM}]
    };

    apiRequest.getCacheManager(cacheNamespaces.API_AUTH).clear();
    return this.request('user_login', request, apiFormatters.MARKETING_JSON, false, cacheNamespaces.API_AUTH);
  }

  /**
   * End user's current session
   *
   * @return {Promise}
   */
  static logout() {
    apiRequest.getCacheManager(cacheNamespaces.API_AUTH).clear();
    return this.request('user_logout', {}, apiFormatters.MARKETING_JSON, false, cacheNamespaces.API_AUTH);
  }

  /**
   * Validates user input and returns third party ID for in app purchases
   *
   * @param {Object} payload
   * @param {Object} payload.accountInfo
   * @param {String} payload.productGroupHash
   * @param {String} payload.paymentType
   * @return {Promise}
   */
  static preSubscribe(payload) {
    const request = {
      args: [
        {
        account_info: payload.accountInfo, // eslint-disable-line
        product_group_hash: payload.productGroupHash, // eslint-disable-line
        payment_type: payload.paymentType, // eslint-disable-line
        platform: environment.PLATFORM // eslint-disable-line
        }
      ]
    };

    return this.request('prospect_preprocesssubscribe', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Validates user input and returns third party ID for in app purchases
   *
   * @param {Object} payload
   * @param {String} payload.productGroupHash
   * @param {String} payload.paymentType
   * @return {Promise}
   */
  static preResubscribe(payload) {
    const request = {
      args: [
        {
        product_group_hash: payload.productGroupHash, // eslint-disable-line
        payment_type: payload.paymentType, // eslint-disable-line
        platform: environment.PLATFORM // eslint-disable-line
        }
      ]
    };

    return this.request('subscriber_preprocessresubscribe', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Subscribes user from the email list.
   * @param {String} email
   * @return {Promise}
   */
  static prospectRegisterEmail(email, origin = '') {
    const request = {
      args: [
        {
          email,
          origin
        }
      ]
    };
    return ApiService.request('prospect_registeremail', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * @param {String} email
   * @param {String} password
   * @param {String} access_code
   * @param {String} platform
   * @return {Promise}
   */
  static redeemCodeProspect(payload) {
    const request = {
      args: [
        {
          account_info: payload.accountInfo,
          access_code: payload.redeemCode,
          redeem_response: payload.schoolInfo,
          platform: environment.PLATFORM
        }
      ]
    };
    return this.request('prospect_redeemcode', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Subscribes the user
   *
   * @param {Object} access_code
   * @return {Promise}
   */
  static redeemCodeMember(access_code) {// eslint-disable-line
    const request = {
      args: [{access_code}] // eslint-disable-line
    };

    return this.request('subscriber_redeemcode', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   *
   * @param {String} completedStep
   * @param {String} userType
   * @return {Promise}
   */
  static regPath(completedStep = '', userType = 'subscriber') {
    const request = {
      args: [
        {
          completed_step: completedStep,  // eslint-disable-line
          subscriber_type: userType  // eslint-disable-line
        }
      ]
    };

    return this.request('registration_path_get_next_step', request, apiFormatters.MARKETING_JSON, false);
  }


  /**
   * Reset the users password
   *
   * @param {String} username
   * @param {String} password
   * @param {String} token
   * @return {Promise}
   */
  static resetPassword(username, password, token) {
    const request = {
      args: [
        {
          username,
          password,
          token
        }
      ]
    };

    return this.request('user_resetpassword', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Resubscribes the user
   *
   * @param {Object} payload
   * @param {String} payload.productGroupHash
   * @param {String} payload.paymentType,
   * @param {Object} payload.billingInfo
   * @return {Promise}
   */
  static resubscribe(payload) {
    const request = {
      args: [
        {
          product_group_hash: payload.productGroupHash, // eslint-disable-line
          payment_type: payload.paymentType, // eslint-disable-line
          billing_info: payload.billingInfo, // eslint-disable-line
          platform: environment.PLATFORM,
          reg_path_key: payload?.regPathKey // optional data
        }
      ]
    };
    return this.request('subscriber_resubscribe', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Restores user's transaction
   *
   * @param {Object} payload
   * @param {String} payload.paymentType,
   * @param {Object} payload.billingInfo
   * @return {Promise}
   */
  static restoreTransaction(payload) {
    const request = {
      args: [
        {
          account_info: payload.accountInfo, // eslint-disable-line
          payment_type: payload.paymentType, // eslint-disable-line
          billing_info: payload.billingInfo, // eslint-disable-line
          platform: environment.PLATFORM
        }
      ]
    };

    return this.request('prospect_restoretransaction', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Subscribes the user
   *
   * @param {Object} payload
   * @param {Object} payload.accountInfo
   * @param {String} payload.productGroupHash
   * @param {String} payload.paymentType,
   * @param {Object} payload.billingInfo
   * @return {Promise}
   */
  static subscribe(payload) {
    localStorage.setItem(localStorageKeys.CURRENT_PRODUCT_HASH, payload.productGroupHash);
    const request = {
      args: [
        {
          account_info: payload.accountInfo, // eslint-disable-line
          product_group_hash: payload.productGroupHash, // eslint-disable-line
          payment_type: payload.paymentType, // eslint-disable-line
          billing_info: payload.billingInfo, // eslint-disable-line
          access_code: payload?.accessCode ? payload?.accessCode : '', // eslint-disable-line
          platform: environment.PLATFORM,
          reg_path_key: payload?.regPathKey // optional data
        }
      ]
    };

    return this.request('prospect_subscribe', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Subscribes the user to a 24hr trial
   *
   * @param {Object} payload
   * @param {Object} payload.accountInfo
   * @param {String} payload.productGroupHash
   * @return {Promise}
   */
  static subscribeTrial(payload) {
    const request = {
      args: [
        {
          account_info: payload.accountInfo, // eslint-disable-line
          product_group_hash: payload.productGroupHash, // eslint-disable-line
          platform: environment.PLATFORM
        }
      ]
    };
    return this.request('prospect_enroll', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Submits the survey
   *
   * @param {Object} payload
   * @param {String} payload.question_id
   * @param {Array} payload.answer_info
   * @return {Promise}
   */
  static submitSurvey(payload) {
    const request = {
      args: [
        {
          answers: payload,
        }
      ]
    };

    return this.request('survey_set_response', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Terminates the user
   * @return {Promise}
   */
  static terminate() {
    const request = {
      args: [{}]
    };

    return this.request('subscriber_terminate', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Upgrades the user to the given product
   *
   * @param {String} product
   * @return {Promise}
   */
  static upgrade(product, completedStep) {
    const request = {
      args: [
        {
          product_group_hash: product,  // eslint-disable-line
          step: completedStep
        }
      ]
    };

    return this.request('subscriber_upgrade', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Upgrades the user to the given product
   *
   * @param {String} product
   * @return {Promise}
   */
  static upgradeGoogle(payload, completedStep) {
    const request = {
      args: [
        {
          billing_info: payload.billingInfo,
          payment_type: payload.paymentType,
          product_group_hash: payload.productHash,  // eslint-disable-line
          step: completedStep
        }
      ]
    };

    return this.request('subscriber_upgrade', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Upgrades the user to the given product
   *
   * @param {String} product
   * @return {Promise}
   */
  static upgradeStandalone(product) {
    const request = {
      args: [
        {
          product_group_hash: product  // eslint-disable-line
        }
      ]
    };

    return this.request('subscriber_nonregpathupgrade', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Extends the users account with the given product
   *
   * @param {String} product
   * @return {Promise}
   */
  static extendAccount(product) {
    const request = {
      args: [
        {
          product_group_hash: product  // eslint-disable-line
        }
      ]
    };

    return this.request('subscriber_onetimeextension', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   *
   * @param {String} accessCode
   * @return {Promise}
   */
  static validateAccessCode(accessCode) {
    const payload = {
      args: [accessCode]
    };

    return this.request('accesscode_validate', payload, apiFormatters.MARKETING_JSON, false);
  }

  /**
   *
   * @return {Promise}
   */
  static validateLogin() {
    return this.request('user_validatelogin', {}, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Validates the given receipt to see if it exists with a current user
   *
   * @param {Object} payload
   * @param {Object} payload.receipt
   * @param {String} payload.paymentType
   * @return {Promise}
   */
  static validateReceipt(payload) {
    const request = {
      args: [
        {
          receipt: payload.receipt,
          payment_type: payload.paymentType  // eslint-disable-line
        }
      ]
    };

    return this.request('prospect_validatereceipt', request, apiFormatters.MARKETING_JSON, false);
  }

  /**
   * Rotation: MKTAA-2373
   * Provides information about the iOS mobile app install source.
   *
   * @param {Object} deviceId
   * @return {Promise}
   */
  static getAdjustCampaign(deviceId) {
    const request = {
      args: [deviceId]
    };

    return this.request('campaign_getadjustcampaign', request, apiFormatters.MARKETING_JSON, false);
  }
  /**
   * Retrieves the Zendesk SSO URL.
   * @return {Promise}
   */
  static getSsoUrl(returnTo = '') {
    const request = {
      args: [
        {
          return_to: returnTo  // eslint-disable-line
        }
      ]
    };

    return this.request('get_zendesk_sso_url', request, apiFormatters.MARKETING_JSON, false);
  }
  /**
   * Readingiq Upsell Poduct
   *
   * @param {String} product
   * @return {Promise}
   */
  static readingiqOffer(product) {
    const request = {
      args: [
        {
          product_hash: product  // eslint-disable-line
        }
      ]
    };

    return this.request('subscriber_purchasereadingiq', request, apiFormatters.MARKETING_JSON, false);
  }
  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static getStates() {
    return this.request('marketingservice_librarygetstates', {}, apiFormatters.MARKETING_JSON, false);
  }
  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static getBranches(payload) {
    const request = {
      args: [
        {
          state: payload
        }
      ]
    };

    return this.request('marketingservice_librarygetbranches', request, apiFormatters.MARKETING_JSON, false);
  }
  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static getLibraryInfo(payload) {
    const request = {
      args: [
        {
          library_id: payload
        }
      ]
    };

    return this.request('marketingservice_librarygetinfo', request, apiFormatters.MARKETING_JSON, false);
  }
  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static validateLibraryInfo(payload) {
    const request = {
      args: [
        {
          library_id: payload.libraryId,
          patron_card_id: payload.libraryCardId,
          pin: payload.libraryCardPin
        }
      ]
    };

    return this.request('marketingservice_libraryvalidate', request, apiFormatters.MARKETING_JSON, false);
  }
  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static validateToken(payload) {
    const request = {
      args: [
        {
          library_token: payload
        }
      ]
    };

    return this.request('marketingservice_libraryvalidatetoken', request, apiFormatters.MARKETING_JSON, false);
  }
  /**
   *
   * @param {Object} payload
   * @return {Promise}
   */
  static createLibraryAccount(payload) {
    const request = {
      args: [
        {
          checkout_id: payload.checkoutId,
          patron_product_id: payload.patronProductId,
          email: payload.email,
          password: payload.password
        }
      ]
    };

    return this.request('marketingservice_librarycreate', request, apiFormatters.MARKETING_JSON, false);
  }
}


export {
  apiRequest,
  ApiService
};
