import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {ApiService} from '../api-service';
import {routerInstance} from '@aofl/router';
import {pages, environment} from '../constants-enumerate';
import {ProductGroupService} from '../product-group-service';
import {UserInfoService} from '../user-info-service';

/**
 * @summary LogoutButton
 * @extends {AoflElement}
 */
@customElement('logout-button')
class LogoutButton extends AoflElement {
  /**
   * Creates an instance of LogoutButton.
   */
  constructor() {
    super();
  }

  /**
   * @readonly
   */
  static is = 'logout-button';

  /**
   * @private
   * @param {Event} e
   */
  async clickHandler(e) {
    e.preventDefault();
    try {
      await ApiService.logout();
      localStorage.removeItem('email');
      ProductGroupService.clear();
      UserInfoService.clear();
      if (environment.IN_APP && environment.IS_DESKTOP) {
        return routerInstance.navigate(pages.LOGIN, {});
      }
      routerInstance.navigate(pages.HOME, {});
    } catch (e) {}
  }

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this.clickHandler);
  }

  /**
   *
   */
  disconnectedCallback() {
    this.removeEventListener('click', this.clickHandler);
    super.disconnectedCallback();
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default LogoutButton;
