/*eslint-disable*/
import {adjustTokens, environment} from '../modules/constants-enumerate';
import {UnityAppCall} from '@aoflmkt/app-call';
import {AppCallService} from '../modules/app-call-service';

class AdjustService {
  /**
   * Initializes the Adjust Web SDK.
   *
   */
  static init() {
    const env = (environment.STAGE || environment.DEV) ? 'sandbox' : 'production';
    // const logLevel = (environment.STAGE || environment.DEV) ? 'verbose' : 'none';
    const logLevel = 'verbose';
    try {
      Adjust.initSdk({
        appToken: adjustTokens.APP_TOKEN,
        environment: env,
        logLevel: logLevel
      });
    } catch (e) {
      return;
    }
  }
  /**
   * Fires an Adjust event.
   * @param {String} eventToken
   * @param {Function} callback
   *
   */
  static track(eventToken, callback) {
    if (environment.IN_APP && !environment.IS_DESKTOP) {
      try {
        UnityAppCall.addListener(callback);
        AppCallService.adjustEvent(eventToken);
      } catch (e) {
        return;
      }
    } else {
      try {
        Adjust.trackEvent({
          eventToken: eventToken
        })
      } catch (e) {
        return;
      }
    }
  }
  /**
   * Returns the Web SDK script to be loaded in our <head> tag.
   *
   */
  static getScript() {
    try {
      return !function(t,e,a,r,s,l,n,d,o){t.Adjust=t.Adjust||{},t.Adjust_q=t.Adjust_q||[];
      for(var c=0;c<l.length;c++)n(t.Adjust,t.Adjust_q,l[c]);d=e.createElement("script"),
      o=e.getElementsByTagName("script")[0],d.async=!0,d.src="https://cdn.adjust.com/adjust-latest-test.min.js",
      d.onload=function(){for(var e=0;e<t.Adjust_q.length;e++)t.Adjust[t.Adjust_q[e][0]].apply(t.Adjust,
      t.Adjust_q[e][1]);t.Adjust_q=[]},o.parentNode.insertBefore(d,o)}(window,document,0,0,0,["initSdk",
      "trackEvent","addGlobalCallbackParameters","addGlobalPartnerParameters","removeGlobalCallbackParameter",
      "removeGlobalPartnerParameter","clearGlobalCallbackParameters","clearGlobalPartnerParameters",
      "switchToOfflineMode","switchBackToOnlineMode","stop","restart","gdprForgetMe"],
      function(t,e,a){t[a]=function(){e.push([a,arguments])}});
    } catch (e) {
      return;
    }
  }
}

export {
  AdjustService
};
