import {resourceEnumerate} from './resource-enumerate';
import {TrackerQueue} from '@aoflmkt/tracker-queue';
import {ServerTime} from '@aoflmkt/server-time';
import {ApiService} from './api-service';
import {apis, cookies} from './constants-enumerate';
import {getDeviceId} from './device';
import Cookies from 'js-cookie';
import {uuid} from '@aofl/uuid';


/**
 *
 * @author Arian Khosravi <arian.khosravi@aofl.com>
 * @extends {TrackerQueue}
 */
class MarketingTrackerQueue extends TrackerQueue {
  /**
   * Creates an instance of MarketingTrackerQueue.
   */
  constructor() {
    super();

    this.interval = null;

    this.resetInterval();

    /* istanbul ignore next */
    window.addEventListener('beforeunload', () => {
      this.processQueue();
    });
  }
  /**
   * Overrides the base class method and decorates the event object with additional data.
   *
   * @param {Object} event
   */
  async enqueueEvent(event) {
    await resourceEnumerate.get(apis.MARKETING);
    event.timestamp = ServerTime.getAdjustedTime(apis.MARKETING);
    event.event_id = uuid();
    event.context = {
      log_type: 'web_client',
      log_version: 1,
      device_id: getDeviceId(),
      token: Cookies.get(cookies.TOKEN),
      app_session_id: Cookies.get(cookies.APP_SESSION_ID) /* eslint-disable-line */
    };
    super.enqueueEvent(event);
  }
  /**
   * Clears the current interval if one exists and starts a new interval and calls processQueue every 10s.
   */
  resetInterval() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.processQueue();
    }, 10000);
  }
  /**
   * Calls send queue and passes a callback function that tries to make a call to event_log.
   *
   * @return {Promise}
   */
  processQueue() {
    return this.sendQueue((queue) => {
      return ApiService.eventLog({events: queue});
    });
  }
}

const marketingTrackerQueue = new MarketingTrackerQueue();

export {
  marketingTrackerQueue
};
