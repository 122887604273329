import {outboundLinks} from '../constants-enumerate';

/**
 * This object is used in init-router-service and will match a vanity
 * url with the appropriate affiliate url.
 */

export const outboundRedirects = {
  '/customer-support/': `${outboundLinks.CUSTOMER_SUPPORT}`,
  '/support/': outboundLinks.CUSTOMER_SUPPORT_CONTACT_US,
  '/support-forgot-password/': `${outboundLinks.CUSTOMER_SUPPORT_FORGOT_PASSWORD}`,
  '/support-forgot-email/': `${outboundLinks.CUSTOMER_SUPPORT_FORGOT_EMAIL}`
};
