import {cacheNamespaces} from './constants-enumerate';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {userInfoSdo} from './sdo-user-info';
import * as Sentry from '@sentry/browser';

class UserInfoService {
  /**
   *
   * @static
   */
  static setUserInfo(apiResp) {
    this.setUserSession(apiResp);
    this.setUserState(apiResp);
    this.setSentryUser(apiResp);
  }
  /**
   *
   * @static
   */
  static setUserSession(apiResp) {
    const userSessionData = new CacheManager(cacheNamespaces.USER_SESSION, cacheTypeEnumerate.LOCAL);
    userSessionData.setItem(cacheNamespaces.USER_SESSION, {
      userUuid: apiResp?.user_uuid, /* eslint-disable-line */
      userAccountType: apiResp?.user_account_type, /* eslint-disable-line */
      nextStep: apiResp?.step, /* eslint-disable-line */
      isActive: apiResp?.is_active, /* eslint-disable-line */
      isLoggedIn: apiResp?.token_valid, /* eslint-disable-line */
      isReactivated: apiResp?.is_reactivated /* eslint-disable-line */
    });
  }
  /**
   *
   * @static
   */
  static setUserState(apiResp) {
    userInfoSdo.userInfo = {
      userUuid: apiResp?.user_uuid, /* eslint-disable-line */
      userAccountType: apiResp?.user_account_type, /* eslint-disable-line */
      nextStep: apiResp?.step, /* eslint-disable-line */
      isActive: apiResp?.is_active, /* eslint-disable-line */
      isLoggedIn: apiResp?.token_valid, /* eslint-disable-line */
      isReactivated: apiResp?.is_reactivated /* eslint-disable-line */
    };
  }
  /**
   *
   * @static
   */
  static setSentryUser(apiResp) {
    Sentry.setUser({id: apiResp?.user_uuid});
    Sentry.addBreadcrumb({
      category: 'user.sdo',
      data: apiResp,
      level: 'info'
    });
  }
  /**
   *
   * @static
   */
  static clear() {
    const userSessionData = new CacheManager(cacheNamespaces.USER_SESSION, cacheTypeEnumerate.LOCAL);
    userSessionData.clear();
    userInfoSdo.reset();
    Sentry.configureScope((scope) => scope.setUser(null));
  }
}

export {
  UserInfoService
};
