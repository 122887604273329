import {simpleModalSdo} from './sdo-simple-modal';
import {storeInstance} from '@aofl/store';
import {html, render} from 'lit-html';
import {layoutSdo} from './sdo-layout';
import {ModalService} from './modal-service';

/**
 * @memberof module:@aofladmin/simple-modal
 */
class SimpleModals {
  /**
   * @param {Object} modals
   */
  static addModals(modals) {
    for (const id in modals) {
      /* istanbul ignore next */
      if (!Object.prototype.hasOwnProperty.call(modals, id)) continue;

      SimpleModals[id] = modals[id];
      simpleModalSdo.add(id);
    }
  }
  /**
   * Hide all modals.
   */
  static hide() {
    simpleModalSdo.hideAll();
  }
  /**
   * Show modal with given id.
   *
   * @param {String} id,
   * @param {*} data
   */
  static show(id, data) {
    simpleModalSdo.show(id, data);
  }
}

const modalContainer = document.createElement('div');
modalContainer.setAttribute('id', 'simple-modal-container');
modalContainer.setAttribute('class', 'simple-modal-container');

const escapeHandler = (e) => {
  if (e.key === 'Escape') {
    ModalService.hide();
  }
};

storeInstance.subscribe(async () => {
  if (simpleModalSdo.activeModalId !== '' && typeof SimpleModals[simpleModalSdo.activeModalId] !== 'undefined') {
    const component = await SimpleModals[simpleModalSdo.activeModalId].resolve();
    let templateStr = '';

    if (typeof component.default === 'undefined') {
      templateStr = component;
    } else {
      templateStr = `<${component.default.is} id="${simpleModalSdo.activeModalId}"></${component.default.is}>`;
    }

    render(html([templateStr]), modalContainer);
    if (modalContainer.parentElement === null) {
      document.body.appendChild(modalContainer);
      document.addEventListener('keyup', escapeHandler);
    }
  } else {
    render(html``, modalContainer);
    if (modalContainer.parentElement !== null) {
      document.removeEventListener('keyup', escapeHandler);
      modalContainer.parentNode.removeChild(modalContainer);
      layoutSdo.overlay = false;
    }
  }
});

export {
  SimpleModals
};
