export default [
  {
    tagName: 'A',
    parents: [
      'LOGIN-FORM',
      'MAIN-HEADER',
      'MAIN-FOOTER',
      'NEW-HEADER',
      'NEW-FOOTER'
    ]
  },
  {
    tagName: 'LINK-TO',
    parents: [
      'PROSPECT-REG-FORM',
      'TOP-PROSPECT-REG-FORM-MOBILE'
    ]
  },
  {
    tagName: 'BUTTON',
    parents: [
      'ACADEMY-PAYMENT-FORM',
      'ACADEMY-REGISTER-FORM',
      'LOGIN-FORM',
      'PROSPECT-REG-FORM',
      'TOP-PROSPECT-REG-FORM-MOBILE'
    ]
  },
  {
    tagName: 'LABEL',
    parents: ['SUBSCRIPTION-FORM']
  },
  {
    tagName: 'MODAL-CLOSE-BUTTON',
    parents: ['ACADEMY-MODAL']
  },
  {
    tagName: 'LOGOUT-BUTTON'
  }
];
