import {Sdo, storeInstance, state, decorate} from '@aofl/store';

/**
 * @type {String}
 * @private
 */
const NAMESPACE = 'aa-simple-modal';

/**
 * @memberof module:@aofladmin/simple-modal
 * @extends {Sdo}
 */
class SimpleModalSdo extends Sdo {
  /**
   * @static
   * @readonly
   * @public
   * @type {String}
   */
  static namespace = NAMESPACE;

  /**
   * @type {Object}
   */
  @state()
  modals = {};

  /**
   * @type {String}
   * @readonly
   */
  @decorate(`${NAMESPACE}.modals`)
  get activeModalId() {
    for (const id in this.modals) {
      /* istanbul ignore next */
      if (!Object.prototype.hasOwnProperty.call(this.modals, id)) continue;

      if (this.modals[id].active) {
        return id;
      }
    }

    return '';
  }

  /**
   * Add an item to modals
   *
   * @param {String} id
   */
  add(id) {
    if (typeof this.modals[id] !== 'undefined') return;
    this.modals = {
      ...this.modals,
      [id]: {
        active: false,
        data: {}
      }
    };
  }

  /**
   * Remove item from modals.
   *
   * @param {String} id
   */
  remove(id) {
    if (typeof this.modals?.[id] === 'undefined') return;

    const modals = Object.assign({}, this.modals);
    delete modals[id];
    this.modals = Object.assign({}, modals);
  }

  /**
   *
   */
  hideAll() {
    for (const id in this.modals) {
      /* istanbul ignore next */
      if (!Object.prototype.hasOwnProperty.call(this.modals, id)) continue;

      if (this.modals[id].active) {
        this.modals = Object.assign({}, this.modals, {
          [id]: {
            ...this.modals[id],
            active: false
          }
        });
        break;
      }
    }
  }

  /**
   *
   * @param {String} id
   */
  show(id, data) {
    this.hideAll();

    this.modals = Object.assign({}, this.modals, {
      [id]: {
        active: true,
        data: data || this.modals[id].data
      }
    });
  }
}

/**
 * @memberof module:@aofladmin/simple-modal
 * @type {SimpleModalSdo}
 */
const simpleModalSdo = new SimpleModalSdo();
storeInstance.addState(simpleModalSdo);

export {
  simpleModalSdo
};
