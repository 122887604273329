import {environment} from '../constants-enumerate';
import * as Sentry from '@sentry/browser';

const RELEASE = `aa-marketing-web-client@${process.env.RELEASE}`;
const IGNORE_ERRORS = [
  'Route Not Found',
  'No qualification order for given route',
  'Version does not exist',
  'No matching conditions',
  'Invalid weights for qualifying rotation',
  'Rotation not found',
  'Rotation route not found'
];

const getEnvironment = () => {
  if (environment.DEV) return 'development';
  if (environment.STAGE) return 'staging';
  if (environment.PROD) return 'production';
};

const initConfig = {
  dsn: 'https://30004a89170449a0870ce3f88c7b27f7@o968153.ingest.sentry.io/5976572',
  release: RELEASE,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: environment.PROD ? 0.3 : 1.0,
  normalizeDepth: 5,
  environment: getEnvironment(),
  ignoreErrors: IGNORE_ERRORS,
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (error && error.success) {
      // Dropping errors at the source level that have a
      // success key: indicative of an API failure.
      return null;
      // Uncomment below to allow for server side filtering:
      // by means of a unique fingerprint.
      // event.fingerprint = ['@aofl-ignore'];
    }
    return event;
  }
};

export {
  initConfig
};
