import resourceEnumerateConfig from './__config/resource-enumerate';
import {ResourceEnumerate} from '@aofl/resource-enumerate';
import {apis} from './constants-enumerate';
import {cookies, environment} from './constants-enumerate';
import {ServerTime} from '@aoflmkt/server-time';
import {environment as serverEnvironment} from './server-environment';
import {marketingTrackerQueue} from './marketing-tracker-queue';
import MarketingJsonFormatter from './__config/marketing-json-formatter';
import Cookies from 'js-cookie';
const resourceEnumerate = new ResourceEnumerate(serverEnvironment);

resourceEnumerate.before((request, response, next) => { // capture local time before
  if (request.namespace === apis.MARKETING && request.cached === false) {
    ServerTime.time(apis.MARKETING);
  }
  next(response);
});

resourceEnumerate.after((request, response, next) => { // capture local time after api response
  if (request.namespace === apis.MARKETING && request.cached === false) {
    ServerTime.timeEnd(response.payload.reference_timestamp, apis.MARKETING);
  }

  next(response);
});

resourceEnumerate.init(resourceEnumerateConfig);

// adding formatter manually because the config constant cannot change with new cookies
delete resourceEnumerate.apiRequestInstance.formatterManager.formatters[apis.MARKETING];
resourceEnumerate.apiRequestInstance.addFormatter(apis.MARKETING, MarketingJsonFormatter);

resourceEnumerate.get(apis.MARKETING)
  .then((data) => {
    Cookies.set(cookies.COUNTRY_CODE, data?.payload?.country_code, cookies.CONFIG); // eslint-disable-line
    const region = (environment.STAGE || environment.DEV) ? 'CA' : data?.payload?.region;
    Cookies.set(cookies.REGION, region, cookies.CONFIG);
    marketingTrackerQueue.processQueue();
  });


export {
  resourceEnumerate
};
