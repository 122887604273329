/* eslint camelcase: "off" */
import {cacheNamespaces, regStepsEnumerate} from './constants-enumerate';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {productGroupSdo} from './sdo-product-group';

class ProductGroupService {
  /**
   *
   * @static
   */
  static getTransformedApiResp(apiResp) {
    return {
      extensionInfo: {
        productGroupHash: apiResp?.product_group_info?.product_group_uuid,
        price: apiResp?.product_group_info?.product_info?.extension_price
      },
      subscriptionInfo: {
        productGroupHash: apiResp?.product_group_hash,
        price: apiResp?.product_info?.renewal_price,
        initialPrice: apiResp?.product_info?.initial_price,
        initialPeriod: apiResp?.product_info?.initial_period
      },
      annualProductInfo: {
        productGroupHash: apiResp?.upgrade_info?.upgrade_annual?.product_group_hash,
        productUuid: apiResp?.upgrade_info?.upgrade_annual?.product_info?.product_uuid,
        price: apiResp?.upgrade_info?.upgrade_annual?.product_info?.initial_price,
        monthsPaid: apiResp?.upgrade_info?.upgrade_annual?.product_info?.months_paid,
        percentDiscount: apiResp?.upgrade_info?.upgrade_annual?.product_info?.percent_discount,
        monthlyPrice: apiResp?.upgrade_info?.upgrade_annual?.product_info?.monthly_price.slice(0, -3),
        annualTermType: apiResp?.upgrade_info?.upgrade_annual?.product_info?.initial_term,
        annualTermPeriod: apiResp?.upgrade_info?.upgrade_annual?.product_info?.initial_period,
        annualMonthsDiscount: apiResp?.upgrade_info?.upgrade_annual?.product_info?.months_discount
      },
      semiAnnualProductInfo: {
        productGroupHash: apiResp?.upgrade_info?.upgrade_semi_annual?.product_group_hash,
        productUuid: apiResp?.upgrade_info?.upgrade_semi_annual?.product_info?.product_uuid,
        price: apiResp?.upgrade_info?.upgrade_semi_annual?.product_info?.initial_price,
        monthsPaid: apiResp?.upgrade_info?.upgrade_semi_annual?.product_info?.months_paid,
        percentDiscount: apiResp?.upgrade_info?.upgrade_semi_annual?.product_info?.percent_discount,
        monthlyPrice: apiResp?.upgrade_info?.upgrade_semi_annual?.product_info?.monthly_price,
        semiAnnualTermType: apiResp?.upgrade_info?.upgrade_semi_annual?.product_info?.initial_term,
        semiAnnualTermPeriod: apiResp?.upgrade_info?.upgrade_semi_annual?.product_info?.initial_period,
        semiAnnualMonthsDiscount: apiResp?.upgrade_info?.upgrade_semi_annual?.product_info?.months_discount
      },
      specialOfferProductInfo: {
        productGroupHash: apiResp?.upgrade_info?.special_upgrade_offer?.product_group_hash,
        productUuid: apiResp?.upgrade_info?.special_upgrade_offer?.product_info?.product_uuid,
        price: apiResp?.upgrade_info?.special_upgrade_offer?.product_info?.initial_price,
        monthsPaid: apiResp?.upgrade_info?.special_upgrade_offer?.product_info?.months_paid,
        percentDiscount: apiResp?.upgrade_info?.special_upgrade_offer?.product_info?.percent_discount,
        monthlyPrice: apiResp?.upgrade_info?.special_upgrade_offer?.product_info?.monthly_price,
        annualTermType: apiResp?.upgrade_info?.special_upgrade_offer?.product_info?.initial_term,
        annualTermPeriod: apiResp?.upgrade_info?.special_upgrade_offer?.product_info?.initial_period,
        annualMonthsDiscount: apiResp?.upgrade_info?.special_upgrade_offer?.product_info?.months_discount
      }
    };
  }
  /**
   *
   * @static
   */
  static setProductGroupStandardRegPath(apiResp) {
    if (apiResp.step !== regStepsEnumerate.UPGRADE) return;

    const productGroupInfo = this.getTransformedApiResp(apiResp);

    const standardRegPathProductGroupSessionData = new CacheManager(cacheNamespaces.PRODUCT_GROUP, cacheTypeEnumerate.LOCAL); /* eslint-disable-line */
    standardRegPathProductGroupSessionData.setItem(cacheNamespaces.PRODUCT_GROUP, {
      annualProductGroupHash: productGroupInfo.annualProductInfo.productGroupHash,
      annualProductUuid: productGroupInfo.annualProductInfo.productUuid,
      annualPrice: productGroupInfo.annualProductInfo.price,
      annualMonthsPaid: productGroupInfo.annualProductInfo.monthsPaid,
      annualPercentDiscount: productGroupInfo.annualProductInfo.percentDiscount,
      annualMonthlyPrice: productGroupInfo.annualProductInfo.monthlyPrice,
      annualTermType: productGroupInfo.annualProductInfo.annualTermType,
      annualTermPeriod: productGroupInfo.annualProductInfo.annualTermPeriod,
      annualMonthDiscount: productGroupInfo.annualProductInfo.annualMonthsDiscount,
      semiAnnualProductGroupHash: productGroupInfo.semiAnnualProductInfo.productGroupHash,
      semiAnnualProductUuid: productGroupInfo.semiAnnualProductInfo.productUuid,
      semiAnnualPrice: productGroupInfo.semiAnnualProductInfo.price,
      semiAnnualMonthsPaid: productGroupInfo.semiAnnualProductInfo.monthsPaid,
      semiAnnualPercentDiscount: productGroupInfo.semiAnnualProductInfo.percentDiscount,
      semiAnnualMonthlyPrice: productGroupInfo.semiAnnualProductInfo.monthlyPrice,
      semiAnnualTermType: productGroupInfo.semiAnnualProductInfo.semiAnnualTermType,
      semiAnnualTermPeriod: productGroupInfo.semiAnnualProductInfo.semiAnnualTermPeriod,
      semiAnnualMonthsDiscount: productGroupInfo.semiAnnualProductInfo.semiAnnualMonthsDiscount,
      subscriptionProductGroupHash: productGroupInfo.subscriptionInfo.productGroupHash,
      subscriptionPrice: productGroupInfo.subscriptionInfo.price,
      subscriptionInitialPeriod: productGroupInfo.subscriptionInfo.initialPeriod
    });

    productGroupSdo.subscriptionInfo = productGroupInfo.subscriptionInfo;
    productGroupSdo.setAnnualProductInfo(productGroupInfo.annualProductInfo);
    productGroupSdo.setSemiAnnualProductInfo(productGroupInfo.semiAnnualProductInfo);
  }
  /**
   *
   * @static
   */
  static setProductGroupNonRegPath(apiResp) {
    const productGroupInfo = this.getTransformedApiResp(apiResp);

    const nonStandardRegPathProductGroupSessionData = new CacheManager(cacheNamespaces.PRODUCT_GROUP, cacheTypeEnumerate.LOCAL); /* eslint-disable-line */
    nonStandardRegPathProductGroupSessionData.setItem(cacheNamespaces.PRODUCT_GROUP, {
      extensionProductGroupHash: productGroupInfo.extensionInfo.productGroupHash,
      extensionPrice: productGroupInfo.extensionInfo.price,
      specialOfferProductGroupHash: productGroupInfo.specialOfferProductInfo.productGroupHash,
      specialOfferProductUuid: productGroupInfo.specialOfferProductInfo.productUuid,
      specialOfferPrice: productGroupInfo.specialOfferProductInfo.price,
      specialOfferMonthsPaid: productGroupInfo.specialOfferProductInfo.monthsPaid,
      specialOfferPercentDiscount: productGroupInfo.specialOfferProductInfo.percentDiscount,
      specialOfferMonthlyPrice: productGroupInfo.specialOfferProductInfo.monthlyPrice,
      specialOfferTermType: productGroupInfo.specialOfferProductInfo.annualTermType,
      specialOfferTermPeriod: productGroupInfo.specialOfferProductInfo.annualTermPeriod,
      specialOfferMonthsDiscount: productGroupInfo.specialOfferProductInfo.annualMonthsDiscount,
    });

    productGroupSdo.subscriptionInfo = productGroupInfo.subscriptionInfo;
    productGroupSdo.extensionInfo = productGroupInfo.extensionInfo;
    productGroupSdo.setAnnualProductInfo(productGroupInfo.annualProductInfo);
    productGroupSdo.setSpecialOfferProductInfo(productGroupInfo.specialOfferProductInfo);
  }
  /**
   *
   * @static
   */
  static clear() {
    const productGroupData = new CacheManager(cacheNamespaces.PRODUCT, cacheTypeEnumerate.LOCAL);
    productGroupData.clear();
    productGroupSdo.reset();
  }
}

export {
  ProductGroupService
};
