import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {isMobile} from '@aoflmkt/is-mobile';
import {accessibilityMixin} from '../accessibility-mixin';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {environment} from '../constants-enumerate';

/**
 * @summary MainHeader
 * @extends {AoflElement}
 */
@customElement('main-header')
class MainHeader extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * Creates an instance of MainHeader.
   */
  constructor() {
    super();
  }

  /**
   * @readonly
   */
  static is = 'main-header';

  /**
   * @return {Object}
   */
  @property({type: Boolean, attribute: 'prevent-redirect'})
  preventRedirect = false;
  @property({type: Boolean, attribute: 'hide-back-button'})
  hideBackButton = false;

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    this.displayBackButton = (isMobile.apple.device && environment.IN_APP && !this.hideBackButton);
    this.displayCloseAppButton = (environment.IN_APP && environment.IS_DESKTOP);
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default MainHeader;
