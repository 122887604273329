import {Rotations} from './rotations-temp';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {apis, rotations, cacheNamespaces, eventNames, cookies, queryParamKeys} from './constants-enumerate';
import rotationConditions from './__config/rotation-conditions';
import routesConfig from './__config/routes.js';
import {marketingTrackerQueue} from './marketing-tracker-queue';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import {resourceEnumerate} from './resource-enumerate';

/**
 * Rotation config object retrieved from S3
 */
const ROTATION_CONFIG = window.aofljsConfig?.rotationConfig;

const THIRTY_DAYS = 2592000;

const FALLBACK_CONFIG = {
  'baseline_version': '0000',
  'conditions': {},
  'qualification_order': {},
  'versions': {},
  'weights': {}
};

/**
 *
 */
class RotationsService {
  /**
   * Determines whether or not to qualify a user for a given rotation based on
   * the last time they visited
   * @param {Function} doRequalify
   * @param {Boolean} forceRequalify
   * @param {Object} exclusionOverrideOpts {wdio: false, intl: false}
   */
  static async rotationRequalifyCheck(doRequalify, forceRequalify = false, exclusionOverrideOpts = {}) {
    const allowRequest = await this.checkGlobalExclusionConditions(exclusionOverrideOpts);
    if (!allowRequest) return false;

    const currentTime = Math.round((new Date()).getTime() / 1000);
    const timeLastVisitedData = new CacheManager(cacheNamespaces.TIME_LAST_VISITED, cacheTypeEnumerate.LOCAL);
    const timeLastVisited = timeLastVisitedData.getItem(cacheNamespaces.TIME_LAST_VISITED);
    if (forceRequalify || currentTime > timeLastVisited + THIRTY_DAYS) {
      return doRequalify();
    }

    return false;
  }
  /**
   * Checks to see if any global exclusion conditions are met, returning false if the request should be excluded
   * @param {Object} exclusionOverrideOpts
   */
  static async checkGlobalExclusionConditions(exclusionOverrideOpts) {
    try {
      const globalExclusionConditions = this.getGlobalExclusionConditions();
      const exclusionConditions = Object.assign({}, globalExclusionConditions, exclusionOverrideOpts);
      for (const key in exclusionConditions) {
        if (exclusionConditions[key] === false) continue;
        if (typeof exclusionConditions[key]?.condition === 'function'
        && await exclusionConditions[key]?.condition() // eslint-disable-line
        ) return false;
      }
      return true;
    } catch (err) {
      Sentry.captureException(err);
    }
  }
  /**
   *
   */
  static getGlobalExclusionConditions() {
    return {
      wdio: {
        condition: () => {
          return Cookies.get(cookies.WDIO) && Cookies.get(cookies.WDIO) === 'baseline';
        }
      },
      intl: {
        condition: async () => {
          const resources = await resourceEnumerate.get(apis.MARKETING);
          return resources?.payload?.country_code !== 'US';
        }
      }
    };
  }
  /**
   *
   * Persisting the last time the user visited the site. For use in rotation conditions
   */
  static setTimeLastVisited() {
    const currentTime = Math.round((new Date()).getTime() / 1000);
    const timeLastVisitedData = new CacheManager(cacheNamespaces.TIME_LAST_VISITED, cacheTypeEnumerate.LOCAL);
    timeLastVisitedData.setItem(cacheNamespaces.TIME_LAST_VISITED, currentTime);
  }
  /**
   *
   * Fires a rotation assignment event, if needed.
   */
  static fireRotationAssignment(currentRotationVersion) {
    if (currentRotationVersion !== ROTATION_CONFIG?.baseline_version) {
      try {
        const rotationData = new CacheManager(cacheNamespaces.ROTATION_VERSION, cacheTypeEnumerate.LOCAL, 7776000000);
        rotationData.setItem(cacheNamespaces.ROTATION_VERSION, currentRotationVersion);
        const rotationAssignmentEvent = {
          event_type: eventNames.ROTATION_ASSIGN,
          payload: {
            rotation_version_id: currentRotationVersion
          }
        };

        marketingTrackerQueue.enqueueEvent(rotationAssignmentEvent);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  }
  /**
   *
   * @static
   * @param {Object} config
   * @param {String} version
   * @return {Object}
   */
  static setVersion(config, version) {
    const currentWeights = config.weights;
    let validVersion = false;

    for (const key in currentWeights) {
      if (!Object.hasOwnProperty.call(currentWeights, key)) continue;
      for (const id in currentWeights[key]) {
        if (!Object.hasOwnProperty.call(currentWeights[key], id)) continue;
        currentWeights[key][id] = 0;

        if (id === version) {
          validVersion = true;
          currentWeights[key][id] = 1;
        }
      }
    }

    if (!validVersion) {
      throw new Error(`The requested rotation version "${version}" does not exist`);
    }

    config.weights = currentWeights;
    return config;
  }

  /**
   * Returns a routes object based on either the retrieved rotation config
   * AWS, or the fallback config if an exception is thrown.
   *
   */
  static async getRoutes() {
    Sentry.setContext('rotation-config', {
      config: JSON.stringify(ROTATION_CONFIG, null, '\t')
    });
    let rotationConfig = ROTATION_CONFIG;

    if (Cookies.get(cookies.WDIO) && Cookies.get(cookies.WDIO) !== 'baseline') {
      rotationConfig = this.setVersion(ROTATION_CONFIG, Cookies.get(cookies.WDIO));
    }

    let routes = {};
    const urlSearchParams = new URLSearchParams(window.location.search);
    const rotationVersion = urlSearchParams.get(queryParamKeys.ROTATION_VERSION);

    if (rotationVersion) {
      rotationConfig = this.setVersion(ROTATION_CONFIG, rotationVersion);
    }

    try {
      const rotationInstance = new Rotations(rotations.MARKETING, routesConfig, rotationConfig, rotationConditions);
      routes = await rotationInstance.getRoutes();
    } catch (err) {
      Sentry.captureException(err);
      const rotationInstance = new Rotations(rotations.MARKETING, routesConfig, FALLBACK_CONFIG, rotationConditions);
      routes = await rotationInstance.getRoutes();
    }

    return routes;
  }
}

export {
  RotationsService
};

