import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {ServerTime} from '@aoflmkt/server-time';
import {ApiService} from './api-service';
import {CampaignService} from './campaign-service';
import {apis, cacheNamespaces, cookiesConsent, environment, eventNames, cookies} from './constants-enumerate';
import {marketingTrackerQueue} from './marketing-tracker-queue';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';
import {uuid} from '@aofl/uuid';
import {productGroupSdo} from './sdo-product-group';

const cacheManager = new CacheManager(cacheNamespaces.CAMPAIGN_PIXELS_LOG, cacheTypeEnumerate.LOCAL);

/**
 * Service to fire pixels using an iframe
 * @class
 */
class PixelService {
  /**
   *
   * @param {String} event (landing|conversion|sign-up)
   * @return {Promise}
   */
  static async fire(event) {
    if (Cookies.get(cookies.WDIO_SUPPRESS_PIXELS)) return;
    await PixelService.fireInternalPixel(event);
    if (environment.IN_APP) return;

    if (Cookies.get(cookiesConsent.ANALYTICS) === 'true') {
      await PixelService.fireCampaignPixel(event);
    }

    await PixelService.fireGlobalPixel(event);
  }

  /**
   *
   * @param {String} event
   * @return {Function}
   */
  static trackEvent(action, event, type) {
    const campaignId = CampaignService.getCampaignInfo()?.id;

    const trackerPayload = {
      event_type: `${eventNames.PIXEL_FIRE}-${action}`,
      payload: {
        event,
        type,
        campaignId
      }
    };

    return marketingTrackerQueue.enqueueEvent(trackerPayload);
  }

  /**
   *
   * @param {String} event
   * @return {Promise}
   */
  static async fireGlobalPixel(event) {
    if (navigator?.globalPrivacyControl) return;
    const {globalPixels} = await import('./__config/pixels');

    if (globalPixels()[event]) {
      PixelService.trackEvent('before', event, 'global');
      await PixelService.attachPixel(globalPixels()[event]);
      PixelService.trackEvent('after', event, 'global');
    }
    PixelService.setGtmEventMetadata(event);
  }

  /**
   *
   * @param {String} event
   * @return {Promise}
   */
  static async fireInternalPixel(event) {
    if (navigator?.globalPrivacyControl) return;
    const {internalPixels} = await import('./__config/pixels');

    if (!internalPixels()[event]) return;

    const pixelsInternal = internalPixels()[event];
    PixelService.trackEvent('before', event, 'internal');

    for (let i = 0; i < pixelsInternal.length; i++) {
      try {
        await fetch(pixelsInternal[i], { // eslint-disable-line
          method: 'GET',
          headers: {
            'Content-type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
          },
          mode: 'no-cors'
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    }

    PixelService.trackEvent('after', event, 'internal');
  }

  /**
   *
   * @param {String} event
   * @return {Promise}
   */
  static async fireCampaignPixel(event) {
    if (navigator?.globalPrivacyControl) return;
    const campaignInfo = CampaignService.getCampaignInfo();
    if (campaignInfo.id !== null && !PixelService.hasPixelFire(campaignInfo.id, event)) {
      const response = await ApiService.resolvePixelInfo(event);

      if (response.payload && response.payload.pixel) {
        PixelService.trackEvent('before', event, 'campaign');
        await PixelService.attachPixel(response.payload.pixel);
        PixelService.trackEvent('after', event, 'campaign');
        PixelService.logPixel(campaignInfo.id, event);
      }
    }
  }

  /**
   *
   * @param {String} campaignId
   * @param {String} event
   * @return {Boolean}
   */
  static hasPixelFire(campaignId, event) {
    const pixelLog = PixelService.getPixelLog();
    let hasPixelFire = false;
    const expiration = ServerTime.getAdjustedTime(apis.MARKETING) - (3600*24*30);

    if (pixelLog[campaignId]
      && pixelLog[campaignId][event]
      && pixelLog[campaignId][event] > expiration
    ) {
      hasPixelFire = true;
    }
    return hasPixelFire;
  }

  /**
   *
   * @return {Object}
   */
  static getPixelLog() {
    const campaignPixels = cacheManager.getItem('pixelLog');
    let pixelLog = {};

    if (campaignPixels) {
      try {
        pixelLog = JSON.parse(campaignPixels);
      } catch (e) {}
    }
    return pixelLog;
  }

  /**
   *
   * @param {String} campaignId
   * @param {String} event
   */
  static logPixel(campaignId, event) {
    const pixelLog = PixelService.getPixelLog();
    pixelLog[campaignId] = pixelLog[campaignId] || {};
    pixelLog[campaignId][event] = ServerTime.getAdjustedTime(apis.MARKETING);
    cacheManager.setItem('pixelLog', JSON.stringify(pixelLog));
  }

  /**
   * Creates and iframe and resolves the promise
   * after the iframe has done loading
   * @param {String} pixel
   * @return {Promise}
   */
  static attachPixel(pixel) {
    return new Promise((resolve, reject) => {
      const iFrame = document.createElement('iframe');
      iFrame.width = 1;
      iFrame.height = 1;
      iFrame.frameborder = 0;
      iFrame.style.display = 'none';

      document.body.appendChild(iFrame);

      iFrame.addEventListener('load', function() {
        resolve();
      });

      iFrame.contentWindow.document.open();
      iFrame.contentWindow.document.write(pixel);
      iFrame.contentWindow.document.close();
    });
  }

  /**
   *
   * @param {String} page
   */
  static trackPageLoad(page) {
    try {
      // Google Analytics
      if (window.gtag) {
        window.gtag('set', 'page', page);
        window.gtag('send', 'pageview');
      }

      // Bing UET
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'page_view', {'page_path': page});
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  /**
   *
   * @return {String}
   */
  static getSourceId() {
    const sourceInfoCookie = Cookies.get(cookies.CAMPAIGN_INFO);
    let sourceId = '';

    if (sourceInfoCookie) {
      const sourceInfo = JSON.parse(decodeURIComponent(sourceInfoCookie));
      sourceId = sourceInfo?.id;
    }

    return sourceId;
  }

  /**
   * @param {String} event
   */
  static async hashValue(string) {
    const stringToLowerCase = string.toLowerCase();
    const utf8 = new TextEncoder().encode(stringToLowerCase);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex.toLowerCase();
  }

  /**
   *
   * @param {String} page
   */
  static async setGtmEventMetadata(event) {
    const elogCookie = Cookies.get(cookies.ELOG);
    let userEmail = '';
    let hashedEmail = '';

    if (elogCookie) {
      userEmail = elogCookie;
    }

    if (userEmail) {
      hashedEmail = await this.hashValue(userEmail);
    }

    const gtmEventMetadata = {
      countryCode: Cookies.get(cookies.COUNTRY_CODE),
      userAgent: navigator?.userAgent ?? '',
      url: window?.location?.href ?? '',
      productHash: productGroupSdo.subscriptionInfo.productGroupHash ?? '',
      sourceTag: PixelService.getSourceId() ?? '',
      timestamp: Date.now(),
      conversionEventID: uuid(),
      externalID: Cookies.get(cookies.CJ_COOKIE) || uuid(),
      email: hashedEmail
    };

    const cjData = Cookies.get(cookies.CJ_COOKIE);
    if (cjData) gtmEventMetadata.cjData = cjData;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(
      {
        'id': gtmEventMetadata.conversionEventID,
        event,
        'eventData': {
          'product': gtmEventMetadata.productHash,
          'timestamp': gtmEventMetadata.timestamp,
          'sourceTag': gtmEventMetadata.sourceTag,
          'userAgent': gtmEventMetadata.userAgent,
          'geo': gtmEventMetadata.countryCode,
          'externalID': gtmEventMetadata.externalID,
          ...(gtmEventMetadata.cjData && {'cjData': gtmEventMetadata.cjData}),
          'hashedEmail': gtmEventMetadata.email,
          'pageUrl': gtmEventMetadata.url,
        }
      }
    );
  }
}

export {
  PixelService
};
