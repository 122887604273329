/**
 * @summary sdo-main-layout
 * @version 1.0.0
 * @since 1.0.0
 * @author Alex Dinari <alex.dinari@aofl.com>
 */
import {Sdo, storeInstance, state} from '@aofl/store';

/**
 * @type {String}
 * @private
 */
const NAMESPACE = 'main-layout';

/**
 * @extends {Sdo}
 */
class LayoutSdo extends Sdo {
  /**
   * @static
   * @type {String}
   */
  static namespace = NAMESPACE;

  /**
   * @static
   * @type {Boolean}
   */
  @state()
  ddMenu = false;

  /**
   * @static
   * @type {Boolean}
   */
  @state()
  displayDebugMenu = false;

  /**
   * @static
   * @type {Boolean}
   */
  @state()
  overlay = false;

  /**
   * @static
   * @type {Boolean}
   */
  @state()
  headerOverlay = false;

  /**
   * @static
   * @type {Boolean}
   */
  @state()
  contentOverlay = false;

  /**
   * @static
   * @type {Boolean}
   */
  @state()
  footerOverlay = false;

  /**
   * @static
   * @type {Object}
   */
  @state()
  scroll = {
    top: 0,
    left: 0,
    freeze: false,
    scrollToTop: false
  }

  /**
   * @static
   * @type {String}
   */
  @state()
  bodyPosition = window.getComputedStyle(document.body).getPropertyValue('position');

  /**
   * @param {Boolean} scrollFreeze
   */
  scrollFreeze(scrollFreeze) {
    this.scroll = Object.assign({}, this.scroll, {freeze: scrollFreeze});
  }
  /**
   * @param {Boolean} resetScroll
   */
  resetScroll() {
    this.scroll = Object.assign({}, this.scroll, {
      freeze: false,
      scrollToTop: false
    });
  }
}

/**
 * @type {LayoutSdo}
 */
const layoutSdo = new LayoutSdo();
storeInstance.addState(layoutSdo);

export {
  layoutSdo
};
