import './route-view';
import './init-router-service';
import './resource-enumerate';
import {environment} from './constants-enumerate';
import {AdjustService} from '../modules/adjust-service';
import {storeInstance} from '@aofl/store';

// Disable scroll jumping when navigating between pages
if (typeof history === 'object' && 'scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}

// Initialize the Adjust Web SDK
AdjustService.init();

/* istanbul ignore next */
if (!environment.DEV && typeof aofljsConfig.__prerender__ === 'undefined') {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.register(environment.PUBLIC_PATH + 'sw.js', { // eslint-disable-line
      scope: environment.PUBLIC_PATH
    });
  }
}

if (environment.DEV) {
  window.storeInstance = storeInstance;
}
