import {marketingTrackerQueue} from './marketing-tracker-queue';
import {eventNames} from './constants-enumerate';

const getPageLoadOrientation = (page) => {
  marketingTrackerQueue.enqueueEvent({
    event_type: eventNames.PAGE_LOAD_ORIENTATION,
    payload: {
      orientation: window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait',
      url: location.origin + page
    }
  });
};

const detectOrientationChange = () => {
  try {
    const orientation = window.matchMedia('(orientation: landscape)');
    orientation.addEventListener('change', (mq) => {
      const currentOrientation = mq.matches ? 'landscape' : 'portrait';
      marketingTrackerQueue.enqueueEvent({
        event_type: eventNames.PAGE_ORIENTATION_CHANGE,
        payload: {
          url: location.href,
          orientation: currentOrientation
        }
      });
    });
  } catch (e) {}
};

export {
  getPageLoadOrientation,
  detectOrientationChange
};
