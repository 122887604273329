import {isMobile} from '@aoflmkt/is-mobile';
import {environment, apis} from '../../constants-enumerate';
import {RotationsService} from '../../rotations-service';
import {resourceEnumerate} from '../../resource-enumerate';

export default () => {
  return RotationsService.rotationRequalifyCheck(async () => {
    const resources = await resourceEnumerate.get(apis.MARKETING);
    const isEligibleCountry = resources?.payload?.country_code === 'US';
    return environment.IN_APP && isMobile.android.device && isEligibleCountry;
  }, false);
};
