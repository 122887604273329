const accessibilityMixin = (superClass) => {
  /**
   * @summary AccessibilityMixin
   * @extends {superClass}
   */
  class AccessibilityMixin extends superClass {
    /**
     * constructor
     */
    constructor(...args) {
      super(...args);
    }

    /**
     * @param {Event} e
     */
    skipNavTo(e) {
      e.preventDefault();
      const id = e.currentTarget.hash.slice(1);
      const target = this.shadowRoot.getElementById(id);
      target.focus();
    }

    /**
     *
     * @memberof AccessibilityMixin
     */
    async connectedCallback() {
      super.connectedCallback();

      await this.updateComplete;
      // Check for the polyfill:
      if (window.applyFocusVisiblePolyfill !== null) {
        window.applyFocusVisiblePolyfill(this.shadowRoot);
      }

      this.enteractiveElements = this.shadowRoot.querySelectorAll('.enteractive');
      if (!this.enteractiveElements) return;

      this.enteractiveElements.forEach((elem) => {
        elem.addEventListener('keydown', (e) => {
          if (e.keyCode === 13) {
            elem.click();
          }
          if (e.keyCode === 32) {
            e.preventDefault();
            elem.click();
          }
        });
      });

      this.checkboxFocusElements = this.shadowRoot.querySelectorAll('#modal-prospect-reg-checkbox-label .enteractive-checkbox');
      if (!this.checkboxFocusElements) return;

      this.checkboxFocusElements.forEach((elem) => {
        elem.addEventListener('keypress', (e) => {
          if (e.keyCode === 13) {
            elem.click();
            elem.focus();
          }
          if (e.keyCode === 32) {
            e.preventDefault();
            elem.click();
            elem.focus();
          }
        });
      });

      this.checkboxFocusElements = this.shadowRoot.querySelectorAll('.enteractive-checkbox');
      if (!this.checkboxFocusElements) return;

      this.checkboxFocusElements.forEach((elem) => {
        elem.addEventListener('keydown', (e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            elem.click();
            elem.focus();
          }
          if (e.keyCode === 32) {
            e.preventDefault();
            elem.click();
            elem.focus();
          }
        });
      });

      this.ddMenuResetFocus = this.shadowRoot.querySelector('.reset-dd-focus');
      this.ddMenuFocus = this.shadowRoot.querySelector('.dd-focus');
      if (this.ddMenuResetFocus) {
        this.ddMenuResetFocus.addEventListener('keydown', (e) => {
          if (e.keyCode === 9) {
            this.ddMenuFocus.focus();
          }
        });
      }

      this.resetFocus = this.shadowRoot.querySelector('.reset-focus');
      if (!this.resetFocus) return;

      this.resetFocus.addEventListener('keydown', (e) => {
        if (e.shiftKey) {
          return;
        }

        if (e.keyCode === 9) {
          this.modalFocus.focus();
        }
      });

      this.tabElements = this.shadowRoot.querySelectorAll('.tab-accessibility');

      this.tabElements.forEach((ele) => {
        ele.addEventListener('keydown', (e) => {
          if (e.shiftKey) {
            e.preventDefault();
            this.shadowRoot.activeElement.focus();
          }
        });
      });
    }

    /**
     *
     */
    async firstUpdated() {
      super.firstUpdated();

      this.modalFocus = this.shadowRoot.getElementById('modal-focus');
      if (!this.modalFocus) return;


      await this.updateComplete;
      this.modalFocus.focus();

      this.closeButton = this.shadowRoot.querySelector('#modal-focus > modal-close-button')?.shadowRoot.querySelector('#modal-close-button');
      if (typeof this.closeButton === 'undefined') return;

      this.modalFocus.addEventListener('keydown', (e) => {
        if (e.shiftKey) {
          if (this.shadowRoot.activeElement === this.modalFocus) {
            e.preventDefault();
            this.closeButton.focus();
          }
        }
      });
    }
  }

  return AccessibilityMixin;
};

export {
  accessibilityMixin
};
