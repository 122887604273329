import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {accessibilityMixin} from '../accessibility-mixin';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {ModalService} from '../modal-service';

/**
 * @summary CdivPage
 * @extends {AoflElement}
 */
@customElement('cdiv-page')
class CdivPage extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * @readonly
   */
  static is = 'cdiv-page';

  /**
   * @param {Event} e
   */
  showCookieConsentModal(e) {
    ModalService.show('cookieConsentModal');
  }

  /**
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default CdivPage;
