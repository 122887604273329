import {pixelEvents} from '../constants-enumerate';

/**
 * This object is used by the CampaignService to build the campaignInfo
 * cookie, this cookie is then used by the PixelService to determine
 * which campaigns should trigger and API call (Campaign/GetPixel) to
 * retrieve and fire pixels.
 */

export default {
  'dis::tw': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::tw:rt': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::pin': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::pin:rt': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::tw:h:w:1': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis:t:w:h:w:2': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::tw:h:a:1': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::tw:h:a:2': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::tw:h:i:1': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::tw:h:i:2': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::tw:h:m:1': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'dis::tw:h:m:2': {
    'pixelEvents': [pixelEvents.LANDING]
  },
  'aofl:abcmouse:nmhptab:1': {
    'pixelEvents': [pixelEvents.LANDING]
  }
};
