import {isMobile} from '@aoflmkt/is-mobile';
import '../picture';

export default (ctx, html) => html`
  ${isMobile.any ? html`
    <button dom-scope="rQjBQSxa" id="menu-button" clas="tab-outline-yellow" type="button" @touchstart="${(e) => ctx.clickHandler(e)}" aria-expanded="${ctx.ddActive}" aria-label="Click to toggle menu button." tabindex="0">
      ${ctx.color === 'gold' ?
        html`
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar1" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-gold-v1.svg">
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar2" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-gold-v1.svg">
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar3" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-gold-v1.svg">
          <img class="menu-icon ${ctx.ddActive ? 'active': ''}" id="caret" alt="" src="/assets/templates/dd-menu-button/caret-gold-v1.svg">
        `:
        html`
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar1" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-v1.svg">
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar2" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-v1.svg">
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar3" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-v1.svg">
          <img class="menu-icon ${ctx.ddActive ? 'active': ''}" id="caret" alt="" src="/assets/templates/dd-menu-button/caret-v1.svg">
      `}
    </button>
  ` : html`
    <button dom-scope="rQjBQSxa" id="menu-button" clas="tab-outline-yellow" type="button" alt="" @click="${(e) => ctx.clickHandler(e)}" aria-expanded="${ctx.ddActive}" aria-label="Click to toggle menu button." tabindex="0">
      ${ctx.color === 'gold' ?
        html`
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar1" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-gold-v1.svg">
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar2" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-gold-v1.svg">
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar3" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-gold-v1.svg">
          <img class="menu-icon ${ctx.ddActive ? 'active': ''}" id="caret" alt="" src="/assets/templates/dd-menu-button/caret-gold-v1.svg">
        `:
        html`
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar1" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-v1.svg">
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar2" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-v1.svg">
          <img class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" id="bar3" alt="" src="/assets/templates/dd-menu-button/horizontal-bar-v1.svg">
          <img class="menu-icon ${ctx.ddActive ? 'active': ''}" id="caret" alt="" src="/assets/templates/dd-menu-button/caret-v1.svg">
      `}
    </button>
  `}
`;
