import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {accessibilityMixin} from '../accessibility-mixin';
import {layoutSdo} from '../sdo-layout';

/**
 * @summary DdMenu
 * @extends {AoflElement}
 */
@customElement('dd-menu')
class DdMenu extends accessibilityMixin(AoflElement) {
  /**
   * Creates an instance of DdMenu.
   */
  constructor() {
    super();
    this.addEventListener('touchstart', (e) => e.stopPropagation());
  }

  /**
   * @readonly
   */
  static is = 'dd-menu';

  /**
   * @return {Object}
   */
  @property({type: Object, attribute: false, store: layoutSdo, mapState: 'ddMenu'})
  ddActive = false;

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default DdMenu;
