import {getServerEnvironment} from '@aofl/server-environment';

const LOCALHOST_REGEX = /localhost|qat|^127|192\.168\.[\d|.]+|172\.(1[6-9]|2[0-9]|3[01])\.[\d|.]+|10\.([0-9]|[1-8][0-9]|9[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.[\d|.]+/;
const STAGE_REGEX = /^dev-|^dev|^(alt-)?stage\./;
const SUBDOMAIN_REGEX = /.*\.(.*?\.(?:com|net))/;
const environment = getServerEnvironment(LOCALHOST_REGEX, STAGE_REGEX);

const getDomain = (hostname = location.hostname) => {
  const matches = SUBDOMAIN_REGEX.exec(hostname);
  if (matches === null) {
    return hostname;
  }

  return matches[1];
};


export {
  getDomain,
  environment
};
