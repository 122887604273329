import {queryParamKeys, pages, cookies} from '../constants-enumerate';
import Cookies from 'js-cookie';

/**
 * This object is used in init-router-service and will match a vanity
 * url with the appropriate affiliate url.
 */

export const redirectUrls = {
  '/coupon/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=aff::ctm:print1`,
  '/parents/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=print::pm`,
  '/finn/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::finn:1`,
  '/pants/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::pants:1`,
  '/tumble/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::tumble:1`,
  '/earth/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::earth:1`,
  '/six/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::six:1`,
  '/nature/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::nature:1`,
  '/mars/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::mars:1`,
  '/benfranklin/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::bf:1`,
  '/mayan/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::mayan:1`,
  '/parenting/': `${pages.HOME}?${queryParamKeys.SOURCE_TAG}=pod::parents:1`,
  '/groupon/': `${pages.REDEEM}?${queryParamKeys.SOURCE_TAG}=aff::groupon`,
  '/comic/': `${pages.SUBSCRIPTION_OFFER_4W0SQ47}?${queryParamKeys.SOURCE_TAG}=print::pm`,
  '/comics/': `${pages.SUBSCRIPTION_OFFER_4W0SQ47}?${queryParamKeys.SOURCE_TAG}=print::pm`,
  '/fluent5000/': `${pages.SUBSCRIPTION}?standalone=fluent5000`,
  '/1e56d9f/': () => {
    Cookies.set(cookies.EMPLOYEE_DISCOUNT, '1', cookies.CONFIG);
    return pages.HOME;
  }, // employee discount
  [`${pages.SUBSCRIPTION_SEASONAL_OFFER_8AB6C33}`]: `${pages.SUBSCRIPTION_OFFER_C09C263}`,
  '/kidnuz/': `${pages.SUBSCRIPTION_OFFER_4W0SQ47}?${queryParamKeys.SOURCE_TAG}=pod::Kidnuz:1`,
};
