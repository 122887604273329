import '../link-to-element';
import {environment} from '../constants-enumerate';

export default (ctx, html) => html`
  ${environment.IS_LINK_FROM_APP ? html`
    <span>TM &amp; &copy; 2007 - ${ctx.currentYear} Age of Learning, Inc. Patents Pending</span>
  ` : html`
    <span>TM &amp; &copy; 2007 - ${ctx.currentYear} <link-to id="aofl-link" dom-scope="wnJdPOjx" href="https://www.ageoflearning.com/" target="_blank" tab-outline-yellow>Age of Learning, Inc.</link-to> Patents Pending</span>
  `}
  <slot></slot>
`;
