/**
 * @summary sdo-user-info
 * @version 1.0.0
 * @since 1.0.0
 * @author Alex Dinari <alex.dinari@aofl.com>
 */
import {Sdo, storeInstance, state} from '@aofl/store';
import {cacheNamespaces} from './constants-enumerate';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {deepAssign} from '@aofl/object-utils';

const userSessionData = new CacheManager(cacheNamespaces.USER_SESSION, cacheTypeEnumerate.LOCAL);
const userData = userSessionData.getItem(cacheNamespaces.USER_SESSION);

/**
 * @type {String}
 * @private
 */
const NAMESPACE = 'user-info';

/**
 * @extends {Sdo}
 */
class UserInfoSdo extends Sdo {
  /**
   * @static
   * @type {String}
   */
  static namespace = NAMESPACE;

  /**
   * @static
   * @type {Object}
   */
  @state()
  userInfo = {
    userUuid: userData?.userUuid || '',
    userAccountType: userData?.userAccountType || '',
    nextStep: userData?.nextStep || '',
    isActive: userData?.isActive,
    isLoggedIn: userData?.isLoggedIn,
    isReactivated: userData?.isReactivated
  };

  /**
   *
   *
   * @param {String} userUuid
   */
  setUserUuid(userUuid) {
    this.userInfo = deepAssign(this.userInfo, '', {
      userUuid
    });
  }

  /**
   *
   *
   * @param {String} userAccountType
   */
  setUserAccountType(userAccountType) {
    this.userInfo = deepAssign(this.userInfo, '', {
      userAccountType
    });
  }

  /**
   *
   *
   * @param {String} nextStep
   */
  setUserNextStep(nextStep) {
    this.userInfo = deepAssign(this.userInfo, '', {
      nextStep
    });
  }

  /**
   *
   *
   * @param {Boolean} isActive
   */
  setUserIsActive(isActive) {
    this.userInfo = deepAssign(this.userInfo, '', {
      isActive
    });
  }

  /**
   *
   *
   * @param {Boolean} isLoggedIn
   */
  setUserIsLoggedIn(isLoggedIn) {
    this.userInfo = deepAssign(this.userInfo, '', {
      isLoggedIn
    });
  }

  /**
   *
   *
   * @param {Boolean} isReactivated
   */
  setUserIsReactivated(isReactivated) {
    this.userInfo = deepAssign(this.userInfo, '', {
      isReactivated
    });
  }
}

/**
 * @type {UserInfoSdo}
 */
const userInfoSdo = new UserInfoSdo();
storeInstance.addState(userInfoSdo);

export {
  userInfoSdo
};
