/**
 * @param {Object} page
 * @return {Object}
 */
const getPageInfo = (page) => {
  let pageId = '';
  const views = [];

  for (const pageObj of page) {
    if (pageObj.name === 'page') {
      pageId = pageObj.id;
    } else if (pageObj.name === 'view') {
      views.push({name: pageObj.id});
    }
  }

  return {
    page_url: '/' + pageId, /* eslint-disable-line */
    page_name: pageId, /* eslint-disable-line */
    view_info: views /* eslint-disable-line */
  };
};

/**
 * Resolves the correct object to be returned, determined by the conditions object passed.
 * Will return the 'default' page if no conditons are met.
 *
 * @param {Object} conditions
 * @return {Object}
 */
const resolveCondition = (conditions) => {
  let condition = {};
  for (condition in conditions) {
    if (!Object.prototype.hasOwnProperty.call(conditions, condition)) continue;
    if (conditions[condition]?.condition()) {
      return conditions[condition];
    }
  }
};

export {
  getPageInfo,
  resolveCondition
};
