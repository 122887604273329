import '../link-to-element';
import {pages} from '../constants-enumerate';

import '../back-button';
import '../close-app-button';
import '../picture';

export default (ctx, html) => html`

  ${ctx.displayBackButton ? html`
    <div class="back-button-container">
      <back-button></back-button>
    </div>
  ` : ''}

  ${ctx.displayCloseAppButton ? html`
    <div class="side-container">
      <close-app-button tabindex="0" class="link button yellow enteractive">Exit Game</close-app-button>
    </div>
  ` : ''}

  ${!ctx.displayCloseAppButton && !ctx.displayBackButton ? html`
    <div class="side-container">
      <slot name="left"></slot>
    </div>
  ` : ''}

  <div id="skip-navigation">
    <slot name="skip-navigation"></slot>
  </div>

  ${ctx.preventRedirect ? html`
    <aofl-picture>
      <aofl-source media="(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)" srcset="/assets/templates/main-header/adventure-academy-logo-dt-v2-2x.png" alt="Logo of Adventure Academy from the creators of ABCmouse"></aofl-source>
      <aofl-source media="(max-width: 568px)" srcset="/assets/templates/main-header/adventure-academy-logo-mb-v2-1x.png" alt="Adventure Academy from the creators of ABCmouse"></aofl-source>
      <aofl-source media="(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) AND (max-width: 568px)" srcset="/assets/templates/main-header/adventure-academy-logo-mb-v2-2x.png" alt="Logo of Adventure Academy from the creators of ABCmouse"></aofl-source>
      <aofl-img src="/assets/templates/main-header/adventure-academy-logo-dt-v2-1x.png" height="117" width="342" alt="Adventure Academy from the creators of ABCmouse"></aofl-img>
    </aofl-picture>
  ` : html`
  <link-to id="logo" dom-scope="u5e27d4L" role="navigation" href="${pages.HOME}" aria-label="Adventure Academy from the creators of A B C mouse" tab-outline-yellow>
    <aofl-picture>
      <aofl-source media="(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)" srcset="/assets/templates/main-header/adventure-academy-logo-dt-v2-2x.png" alt="Logo of Adventure Academy from the creators of ABCmouse"></aofl-source>
      <aofl-source media="(max-width: 568px)" srcset="/assets/templates/main-header/adventure-academy-logo-mb-v2-1x.png" alt="Logo of Adventure Academy from the creators of ABCmouse"></aofl-source>
      <aofl-source media="(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) AND (max-width: 568px)" srcset="/assets/templates/main-header/adventure-academy-logo-mb-v2-2x.png" alt="Logo of Adventure Academy from the creators of ABCmouse"></aofl-source>
      <aofl-img src="/assets/templates/main-header/adventure-academy-logo-dt-v2-1x.png" height="117" width="342" alt="Logo of Adventure Academy from the creators of ABCmouse"></aofl-img>
    </aofl-picture>
  </link-to>
  `}

  <div id="right" class="side-container">
    <slot name="right"></slot>
  </div>
`;
