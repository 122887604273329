import {routerInstance} from '@aofl/router';
import {modalsConfig} from './__config/modals';
import {RotationsService} from './rotations-service';
import {outboundRedirects} from './__config/outbound-redirects';
import {redirectUrls} from './__config/redirect-urls';
import {ApiService} from './api-service';
import {CampaignService} from './campaign-service';
import {marketingTrackerQueue} from './marketing-tracker-queue';
import {getPageInfo, resolveCondition} from './page-load-utils';
import {PixelService} from './pixel-service';
import {ProductGroupService} from './product-group-service';
import {layoutSdo} from './sdo-layout';
import {userInfoSdo} from './sdo-user-info';
import {UserInfoService} from './user-info-service';
import {ModalService} from '../modules/modal-service';
import Cookies from 'js-cookie';
import {aoflSources, cookies, environment, eventNames, pages, pixelEvents, queryParamKeys} from './constants-enumerate';
import * as Sentry from '@sentry/browser';
import {detectOrientationChange, getPageLoadOrientation} from '../modules/device-orientation';

/**
 * Adding global modals
 */
ModalService.addModals(modalsConfig);

/**
 * Configuring Initial Sentry.io Scope
 */
Sentry.configureScope(function(scope) {
  const source = decodeURIComponent(Cookies.get(cookies.CAMPAIGN_INFO));
  scope.setTag('inApp', environment.IN_APP);

  if (Cookies.get(cookies.WDIO)) {
    scope.setContext('wdio-context', {
      testEmail: decodeURIComponent(Cookies.get(cookies.WDIO_TEST_EMAIL)),
      sessionUrl: decodeURIComponent(Cookies.get(cookies.WDIO_SESSION_ID)),
    });
  }

  if (environment.IN_APP) {
    scope.setTag(cookies.APP_STORE, Cookies.get(cookies.APP_STORE));
  }

  scope.setTag('wdio', Boolean(Cookies.get(cookies.WDIO)));
  scope.setTag('source', source !== 'undefined' ? source : 'Organic');
  scope.setTag(cookies.DEVICE_ID, Cookies.get(cookies.DEVICE_ID));
  scope.setTag(cookies.APP_SESSION_ID, Cookies.get(cookies.APP_SESSION_ID));
  scope.setTag(cookies.COUNTRY_CODE, Cookies.get(cookies.COUNTRY_CODE));
  scope.setTag(cookies.REGION, Cookies.get(cookies.REGION));
});

// Detecting device orientation changes
detectOrientationChange();

/**
 * Intializing rotation service.
 */
const ready = RotationsService.getRoutes()
  .then((routes) => {
    // ------------------------------------------------------------------------>
    routerInstance.init(routes);
    // ------------------------------------------------------------------------>
    /**
     *
     * Persisting the last time the user visited the site. For use in rotation conditions
     */
    RotationsService.setTimeLastVisited();
    // ------------------------------------------------------------------------>
    /**
     *
     * Updates store with server session values.
     */
    routerInstance.beforeEach(async (request, response, next) => {
      if (response?.matchedRoute?.meta?.blockedForMembers || response?.matchedRoute?.meta?.requiresAuthentication) {
        try {
          const validateLoginResp = await ApiService.validateLogin();
          let regPathResp = '';
          if (validateLoginResp?.payload?.token_valid) {
            regPathResp = await ApiService.regPath();
          }
          ProductGroupService.setProductGroupStandardRegPath(validateLoginResp.payload);
          UserInfoService.setUserInfo({
            user_uuid: userInfoSdo.userInfo.userUuid,
            user_account_type: userInfoSdo.userInfo.userAccountType,
            step: regPathResp?.payload?.step,
            is_active: Boolean(validateLoginResp.payload.is_active),
            token_valid: Boolean(validateLoginResp?.payload?.token_valid),
            is_reactivated: userInfoSdo.userInfo.isReactivated
          });
        } catch (err) {
          Sentry.captureException(err);
        } finally {
          next(response);
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Check to see if offer is within the expiration range.
     */
    routerInstance.beforeEach((request, response, next) => {
      const expiration = response?.matchedRoute?.meta?.expiration;

      if (expiration) {
        const currentTime = Math.floor(Date.now() / 1000);
        const isActive = expiration?.activeRanges?.some((range) => {
          return currentTime > range?.startTime && currentTime < range?.endTime;
        });

        const urlSearchParams = new URLSearchParams(window.location.search);
        const urlContainsBypass = urlSearchParams.get(queryParamKeys.EXPIRATION_BYPASS_KEY) === queryParamKeys.EXPIRATION_BYPASS_VALUE; // eslint-disable-line

        if (!isActive && !urlContainsBypass) {
          if (expiration?.redirectURL) {
            response.to = expiration?.redirectURL;
          } else {
            response.matchedRoute = null;
          }
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects to the appropriate affiliate url if the requested url is a valid vanity url.
     * Also adds employee discount cookie if employee pages is requested
     */
    routerInstance.beforeEach((request, response, next) => {
      const url = response?.to?.toLowerCase().split('?')[0];
      const urlParams = response?.to?.split('?')[1];
      const redirectUrl = typeof redirectUrls[url] === 'function' ? redirectUrls[url]() : redirectUrls[url];
      if (redirectUrl) {
        const paramKey = redirectUrl.split('?')[1] ? '&' : '?';
        response.to = urlParams ? redirectUrl + paramKey + urlParams : redirectUrl;
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     * Redirects users who are logged in to the Customer Support page instead of being sent to the SSO CS Log In page.
     */
    routerInstance.beforeEach(async (request, response, next) => {
      const isLoggedIn = userInfoSdo.userInfo.isLoggedIn;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const ssoSource = urlSearchParams.get(queryParamKeys.SSO);
      let returnTo = urlSearchParams.get('return_to');
      if (returnTo === null) {
        returnTo = '';
      }

      if (isLoggedIn && (ssoSource === aoflSources.ZENDESK)) {
        try {
          const ssoResponse = await ApiService.getSsoUrl(returnTo);
          window.location = ssoResponse.payload.url;
        } catch (err) {
          Sentry.captureException(err);
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects to the appropriate outbound url if the requested url is a valid outbound redirect url.
     */
    routerInstance.beforeEach((request, response, next) => {
      const url = response?.to?.toLowerCase().split('?')[0];
      const outboundUrl = outboundRedirects[url];
      if (outboundUrl) {
        window.location = outboundUrl;
        return;
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Redirects to /login if the user isnt logged in when requesting a protected route, also
     * redirects to download app if conditions are met.
     */
    routerInstance.beforeEach((request, response, next) => {
      const isLoggedIn = userInfoSdo.userInfo.isLoggedIn;
      const userInfo = userInfoSdo.userInfo;

      const redirectConditions = {
        deactivatedAndRequestingHomePage: {
          condition: () => {
            return isLoggedIn && !userInfo.isActive && response?.matchedRoute?.path === pages.HOME
              && !environment.IN_APP;
          },
          resolve: () => {
            response.to = pages.REACTIVATION;
            response.popped = false;
          }
        },
        loggedInAndRequestingPageBlockedForMembers: {
          condition: () => {
            return isLoggedIn && response?.matchedRoute?.meta?.blockedForMembers;
          },
          resolve: () => {
            const nextStep = userInfo.nextStep;
            response.to = pages[nextStep] || request.to;
            response.popped = false;
          }
        },
        notLoggedInAndPageRequiresAuthentication: {
          condition: () => {
            return response?.matchedRoute?.meta?.requiresAuthentication && !isLoggedIn;
          },
          resolve: () => {
            response.to = pages.LOGIN + `?to=${encodeURIComponent(request.to)}`;

            const QUERY_STRING_REGEX = /[^=?&]+=[^&]+/g;
            const parameters = request.to.match(QUERY_STRING_REGEX);

            if (parameters && parameters.length > 0) {
              parameters.forEach((param) => {
                if (key === 'to') {
                  return;
                }
                const [key, val] = param.split('=');
                response.to += `&${key}=${encodeURIComponent(val)}`;
              });
            }
            response.popped = false;
          }
        },
        default: {
          condition: () => true,
          resolve: () => {}
        }
      };

      const resolvedCondition = resolveCondition(redirectConditions);
      resolvedCondition.resolve();

      next(response);
    });
    // ----------------------------------------------------------------------->
    /**
     *
     * Calls Subscriber/GetSpecialOfferUpgradeInfo and sets product group state if logged in user is requesting the standalone upgrade
     *
     */
    routerInstance.after(async (request, response, next) => {
      const isLoggedIn = userInfoSdo.userInfo.isLoggedIn;
      const requestPath = response?.matchedRoute?.path + '/';

      if (isLoggedIn && requestPath === pages.UPGRADE_STANDALONE && !environment.IN_APP) {
        try {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const productGroupHash = decodeURIComponent(urlSearchParams.get(queryParamKeys.PRODUCT_GROUP_HASH));
          const productGroupInfoResp = await ApiService.getStandaloneUpgradeOfferInfo(productGroupHash);

          if (!productGroupInfoResp?.payload?.is_upgrade_eligible) { // eslint-disable-line
            window.location = pages.OFFER_EXPIRED;
          }

          ProductGroupService.setProductGroupNonRegPath(productGroupInfoResp.payload);
        } catch (e) {
          Sentry.captureException(e);
          window.location = pages.OFFER_EXPIRED;
        }
      }
      next(response);
    });
    // ----------------------------------------------------------------------->
    /**
     *
     * Calls Subscriber/GetExtendAccountInfo and sets product group state if logged in user is requesting the extend account page
     *
     */
    routerInstance.after(async (request, response, next) => {
      const isLoggedIn = userInfoSdo.userInfo.isLoggedIn;
      const requestPath = response?.matchedRoute?.path + '/';

      if (isLoggedIn && requestPath === pages.EXTEND_ACCOUNT && !environment.IN_APP) {
        try {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const productGroupHash = decodeURIComponent(urlSearchParams.get(queryParamKeys.PRODUCT_GROUP_HASH));
          const productGroupInfoResp = await ApiService.getExtendAccountInfo(productGroupHash);

          if (!productGroupInfoResp?.payload?.is_extension_eligible) { // eslint-disable-line
            window.location = pages.OFFER_EXPIRED;
          }

          ProductGroupService.setProductGroupNonRegPath(productGroupInfoResp.payload);
        } catch (e) {
          Sentry.captureException(e);
          window.location = pages.OFFER_EXPIRED;
        }
      }
      next(response);
    });
    // ----------------------------------------------------------------------->
    /**
     *
     * Redirects the user to home if the requested path is email preferences,
     * if the user does not have an email_unsubscribe cookie and the recepient id is anything but '0'.
     */
    routerInstance.beforeEach((request, response, next) => {
      if (response?.matchedRoute?.path + '/' === pages.EMAIL_PREFERENCES) {
        const email = Boolean(Cookies.get(cookies.EMAIL_UNSUBSCRIBE));
        const recepientId = routerInstance.currentRoute.matchedRoute?.props?.recepient_id === '0'; // eslint-disable-line
        if (!email && !recepientId) {
          response.to = pages.HOME;
          response.popped = false;
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Call logout to destroy the server session, clear the browser's
     * cookies, localStorage, sessionStorage, wipe session sdo, and
     * force refresh user to home.
     *
     */
    routerInstance.beforeEach(async (request, response, next) => {
      if (response.to === '/clear/' || (response.to.includes('/clear') && Cookies.get(cookies.WDIO))) {
        const isLoggedIn = userInfoSdo.userInfo.isLoggedIn;
        if (isLoggedIn) {
          try {
            await ApiService.logout();
          } catch (err) {
            Sentry.captureException(err);
          }
        }

        Object.keys(Cookies.get()).forEach((cookieName) => {
          const wdioCookie = cookieName === cookies.WDIO || cookieName === cookies.WDIO_SUPPRESS_PIXELS;
          if (Cookies.get(cookies.WDIO) && wdioCookie) return;
          Cookies.remove(cookieName, cookies.CONFIG);
        });

        localStorage.clear();
        sessionStorage.clear();

        if (!Cookies.get(cookies.WDIO)) {
          window.location = '/';
          return;
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires redirect to login page and will remove the localStorage item if
     * the click is from login page
     */
    routerInstance.beforeEach((request, response, next) => {
      const isLoggedIn = userInfoSdo.userInfo.isLoggedIn;
      if (environment.IN_APP && !isLoggedIn) {
        const validToLogin = (response.to !== '/login/' && response.to !== '/download/' && response.to !== '/web/parent/1.0/settings/');
        if (response.from.includes('/login') && validToLogin) {
          if (localStorage.getItem('redirectToLogin')) {
            localStorage.removeItem('redirectToLogin');
          }

          Cookies.remove('redirectToLogin', cookies.CONFIG);
        } else if (validToLogin) {
          if (localStorage.getItem('redirectToLogin')) {
            localStorage.removeItem('redirectToLogin');
          }

          const redirectToLogin = Cookies.get('redirectToLogin');
          if (redirectToLogin) {
            response.to = pages.LOGIN + location.search;
          }
        }
      }
      next(response);
    });

    // ----------------------------------------------------------------------->
    /**
     *
     * Redirects the user to the 404 page if the requested route requires a redeem code and the code is invalid.
     */
    routerInstance.beforeEach(async (request, response, next) => {
      const isLoggedIn = userInfoSdo.userInfo.isLoggedIn;
      const isActive = userInfoSdo.userInfo.isActive;
      const url = response?.to?.toLowerCase().split('?')[0];
      const urlSearchParams = new URLSearchParams(window.location.search);
      let redeemCode = '';
      if (url === pages.SUBSCRIPTION) {
        redeemCode = urlSearchParams.get(queryParamKeys.REDEEM_CODE);
      }
      if (response?.matchedRoute?.meta?.requiresValidRedeemCode) {
        let redeemCode = response.matchedRoute.props.redeemCode;
        const queryStringIndex = redeemCode.indexOf(`?${queryParamKeys.SOURCE_TAG}`);
        if (queryStringIndex !== -1) {
          redeemCode = redeemCode.slice(0, queryStringIndex);
        }
      }
      if (redeemCode) {
        try {
          await ApiService.validateAccessCode(redeemCode);
        }
        catch (e) {
          Sentry.captureException(e);
          response.to = isLoggedIn && !isActive ? pages.REACTIVATION : pages.SUBSCRIPTION;
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Sets the 'cje' cookie for the CJ from the cjevent query param.
     */
    routerInstance.afterEach((request, response, next) => {
      if (response.to.includes(queryParamKeys.CJ_EVENT)) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const cjEvent = urlSearchParams.get(queryParamKeys.CJ_EVENT);
        if (cjEvent) {
          Cookies.set(cookies.CJ_COOKIE, cjEvent, cookies.CJ_CONFIG);
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires the campaign attribution event if the URL contains the 'src_tag'
     * query string parameter
     */
    routerInstance.after((request, response, next) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const sourceTag = urlSearchParams.get(queryParamKeys.SOURCE_TAG);

      if (sourceTag) {
        CampaignService.attribute(sourceTag);
      }

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires landing pixel event
     */
    routerInstance.after(async (request, response, next) => {
      if (!environment.IN_APP) {
        await PixelService.fireGlobalPixel(pixelEvents.LANDING);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const landingPixel = urlSearchParams.get(queryParamKeys.LANDING_PIXEL);

        // For 'legacy landing pixels, that existed prior to the 'LANDING_PIXEL' implementation
        const sourceTag = urlSearchParams.get(queryParamKeys.SOURCE_TAG);
        const legacyLandingPixel = CampaignService.getPixelEvents(sourceTag);

        if (landingPixel || legacyLandingPixel.length > 0) {
          await PixelService.fireCampaignPixel(pixelEvents.LANDING);
        }

        const pageSpecificPixelEvents = response?.matchedRoute?.meta?.pixelEvents;

        if (pageSpecificPixelEvents) {
          pageSpecificPixelEvents.forEach(async (pixelEvent) => {
            if (pixelEvents[pixelEvent]) {
              await PixelService.fire(pixelEvents[pixelEvent]);
            }
          });
        }
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires page load events
     */
    routerInstance.after((request, response, next) => {
      const urlParamObj = {};
      const urlSearchParams = new URLSearchParams(window.location.search);

      const urlParamsArray = urlSearchParams.toString().split('&');
      urlParamsArray.forEach((param) => {
        const [key, val] = param.split('=');
        urlParamObj[key] = val;
      });

      const pageEvent = {
        event_type: eventNames.PAGE_LOAD,
        payload: {
          url: location.origin + response.to,
          referrer: request.from.replace(/^\//, location.origin + '/'),
          params_query_json: JSON.stringify(urlParamObj) /* eslint-disable-line */
        }
      };

      pageEvent.payload.page = response?.matchedRoute?.meta?.pageInfo || [
        {
          name: 'page',
          id: '404'
        }
      ];

      pageEvent.payload.page_info = getPageInfo(pageEvent.payload.page); /* eslint-disable-line */

      getPageLoadOrientation(response.to);
      marketingTrackerQueue.enqueueEvent(pageEvent);
      PixelService.trackPageLoad(response.to);
      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Fires a rotation assignment event, if needed.
     */
    routerInstance.after((request, response, next) => {
      const currentRotationVersion = response?.matchedRoute?.rotationInfo?.version;
      RotationsService.fireRotationAssignment(currentRotationVersion);

      next(response);
    });
    // ------------------------------------------------------------------------>
    /**
     *
     * Close all modals and dropdown menus
     */
    routerInstance.after((request, response, next) => {
      if (typeof layoutSdo !== 'undefined') {
        ModalService.hide();
        layoutSdo.reset();
      }
      next(response);
    });
    // ------------------------------------------------------------------------>
    routerInstance.navigate(location.href.replace(location.origin, '').replace('index.html', ''), {forceReload: true, replaceState: true});
    // ------------------------------------------------------------------------>
  });

export {
  ready,
  routerInstance
};
