import Cookie from 'js-cookie';
import {cookies, queryParamKeys} from './constants-enumerate';
import {uuid} from '@aofl/uuid';
import {PathUtils} from '@aofl/router';

const getToken = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const academyToken = urlSearchParams.get(queryParamKeys.TOKEN);
  if (academyToken) {
    Cookie.remove(cookies.TOKEN, {
      secure: cookies.CONFIG.secure
    });
    Cookie.set(cookies.TOKEN, academyToken, cookies.CONFIG);
    Cookie.set(cookies.CLIENT_BACKUP_TOKEN, academyToken, cookies.CONFIG);
  }
};

const getAcademyInApp = () => {
  if (Cookie.get(cookies.IN_APP)) return;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const academyInApp = urlSearchParams.get(queryParamKeys.IN_APP);
  Cookie.set(cookies.IN_APP, academyInApp, cookies.CONFIG);
};

const getAcademyAppStore = () => {
  if (Cookie.get(cookies.APP_STORE)) return;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const academyAppStore = urlSearchParams.get(queryParamKeys.APP_STORE);
  Cookie.set(cookies.APP_STORE, academyAppStore, cookies.CONFIG);
};

const getSessionId = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const academyAppSessionId = urlSearchParams.get(queryParamKeys.APP_SESSION_ID);
  if (academyAppSessionId) {
    Cookie.remove(cookies.APP_SESSION_ID, {
      secure: cookies.CONFIG.secure
    });
    Cookie.set(cookies.APP_SESSION_ID, academyAppSessionId, cookies.CONFIG);
  }
};

const getLinkFromApp = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let linkFromApp = urlSearchParams.get(queryParamKeys.LINK_FROM_APP);
  linkFromApp = PathUtils.removeTrailingSlash(linkFromApp);
  if (linkFromApp && (linkFromApp === 'android' || linkFromApp === 'ios')) {
    Cookie.remove(cookies.LINK_FROM_APP, {
      secure: cookies.CONFIG.secure
    });
    Cookie.set(cookies.LINK_FROM_APP, linkFromApp, cookies.CONFIG);
  }
};

const getDeviceId = () => {
  getToken();
  getSessionId();
  getAcademyInApp();
  getAcademyAppStore();
  getLinkFromApp();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const academyDeviceId = urlSearchParams.get(queryParamKeys.DEVICE_ID);
  let savedDeviceId = '';

  if (academyDeviceId) {
    Cookie.remove(cookies.DEVICE_ID, {
      secure: cookies.CONFIG.secure
    });
    Cookie.set(cookies.DEVICE_ID, academyDeviceId, cookies.CONFIG);
    savedDeviceId = academyDeviceId;
  } else if (Cookie.get(cookies.DEVICE_ID)) {
    savedDeviceId = Cookie.get(cookies.DEVICE_ID);
  }

  if (typeof savedDeviceId !== 'undefined' && savedDeviceId !== '') return savedDeviceId;

  const deviceId = uuid();
  Cookie.set(cookies.DEVICE_ID, deviceId, cookies.CONFIG);

  return deviceId;
};

export {
  getDeviceId
};
