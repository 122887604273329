import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {AppCallService} from '../app-call-service';

/**
 * @summary CloseAppButton
 * @extends {AoflElement}
 */
@customElement('close-app-button')
class CloseAppButton extends AoflElement {
  /**
   * @readonly
   */
  static is = 'close-app-button';

  /**
   * @param {Event} e
   */
  clickHandler(e) {
    e.preventDefault();
    try {
      AppCallService.closeApp();
    } catch (e) {}
  }

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this.clickHandler);
  }

  /**
   *
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('click', this.clickHandler);
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default CloseAppButton;
