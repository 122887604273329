import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {ModalService} from '../modal-service';
import {layoutSdo} from '../sdo-layout';

/**
 * @summary MainLayout
 * @extends {AoflElement}
 */
@customElement('main-layout')
class MainLayout extends AoflElement {
  /**
   * @readonly
   */
  static is = 'main-layout';

  @property({type: Boolean})
  'full-height' = false;

  @property({type: Boolean, attribute: false, store: layoutSdo, mapState: 'displayDebugMenu'})
  displayDebugMenu = false;

  @property({type: Boolean, attribute: false, store: layoutSdo, mapState: 'headerOverlay'})
  headerOverlay = false;

  @property({type: Boolean, attribute: false, store: layoutSdo, mapState: 'contentOverlay'})
  contentOverlay = false;

  @property({type: Boolean, attribute: false, store: layoutSdo, mapState: 'footerOverlay'})
  footerOverlay = false;

  @property({type: Boolean, attribute: false, store: layoutSdo, mapState: 'overlay'})
  overlay = false;

  @property({type: Object, attribute: false, store: layoutSdo, mapState: 'scroll'})
  scroll = {};

  @property({type: String, attribute: false, store: layoutSdo, mapState: 'bodyPosition'})
  bodyPosition = window.getComputedStyle(document.body).getPropertyValue('position');

  @property({type: Array})
  mediaQueries = [
    window.matchMedia('(max-width: 823px) and (orientation: landscape)'),
    window.matchMedia('(max-width: 568px)')
  ];

  /**
   *
   */
  showDebugMenu(e) {
    ModalService.show('debugMenu');
  }

  /**
   *
   */
  resetDDMenu() {
    if (layoutSdo.ddMenu === false) return;
    layoutSdo.ddMenu = false;
    layoutSdo.contentOverlay = false;
    layoutSdo.footerOverlay = false;
  }

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();

    for (let i = 0; i < this.mediaQueries.length; i++) {
      this.mediaQueries[i].addListener(this.resetDDMenu);
    }
  }

  /**
   *
   */
  shouldUpdate(props) {
    if (props.has('overlay') || props.has('contentOverlay')) {
      if (this.overlay) {
        this.classList.add('show-overlay');
      } else {
        this.classList.remove('show-overlay');
      }
    }

    if (props.has('scroll')) {
      const bodyPosition = window.getComputedStyle(document.body).getPropertyValue('position');
      if (this.scroll.scrollToTop) {
        document.body.style.position = 'fixed';
        document.body.style.top = '0px';
        document.body.style.left = '0px';
      } else if (this.scroll.freeze && bodyPosition !== 'fixed') {
        layoutSdo.bodyPosition = bodyPosition;
        document.body.style.position = 'fixed';
        document.body.style.top = (-1 * this.scroll.top) + 'px';
        document.body.style.left = (-1 * this.scroll.left) + 'px';
      } else if (!this.scroll.freeze && bodyPosition === 'fixed') {
        document.body.style.position = layoutSdo.bodyPosition;
        window.scrollTo(this.scroll.left, this.scroll.top);
      }
    }
    return props.has('overlay') || props.has('contentOverlay') || props.has('scroll');
  }

  /**
   *
   */
  disconnectedCallback() {
    super.disconnectedCallback();

    for (let i = 0; i < this.mediaQueries.length; i++) {
      this.mediaQueries[i].removeListener(this.resetDDMenu);
    }
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default MainLayout;
