import {appCalls, appCallCallbacks, cookies, cookiesConsent} from './constants-enumerate'; // eslint-disable-line
import {UnityAppCall} from '@aoflmkt/app-call';
import {userInfoSdo} from './sdo-user-info';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';

/**
 *
 * @param {String} url
 * @return {Object}
 */
const parseURL = (url = '') => {
  const httpRegex = /(https?):\/\/(.*)/;
  const matches = url.match(httpRegex);
  const out = {
    schema: '',
    href: ''
  };

  if (matches !== null) {
    out.schema = matches[1];
    out.href = matches[2];
  }

  return out;
};

/**
 *
 * @author Arian Khosravi <arian.khosravi@aofl.com>
 */
class AppCallService {
  /**
   *
   * @param {String} url
   * @param {String} [target='']
   */
  static navigate(url, target = '') {
    const params = {
      ...parseURL(url),
      target
    };

    Sentry.addBreadcrumb({
      category: 'app-call',
      message: appCalls.NAVIGATE,
      data: params,
      level: 'info'
    });

    UnityAppCall.send(appCalls.NAVIGATE, params);
  }

  /**
   *
   * @param {Object} payload
   * @param {String} payload.token
   * @param {String} payload.userUuid
   * @param {String} payload.userAccountType
   */
  static login(payload) {
    Sentry.addBreadcrumb({
      category: 'app-call',
      message: appCalls.LOGIN,
      data: payload,
      level: 'info'
    });

    UnityAppCall.send(appCalls.LOGIN, {
      token: payload.token, // eslint-disable-line
      user_uuid: payload.userUuid, // eslint-disable-line
      user_account_type: payload.userAccountType // eslint-disable-line
    });
  }

  /**
   *
   * @param {Object} params
   */
  static purchase(params) {
    Sentry.addBreadcrumb({
      category: 'app-call',
      message: appCalls.PURCHASE,
      data: params,
      level: 'info'
    });
    UnityAppCall.send(appCalls.PURCHASE, params);
  }

  /**
   *
   * @param {Object} params
   */
  static upgrade(params) {
    Sentry.addBreadcrumb({
      category: 'app-call',
      message: appCalls.UPGRADE,
      data: params,
      level: 'info'
    });
    UnityAppCall.send(appCalls.UPGRADE, params);
  }

  /**
   *
   * @param {String} request
   */
  static restoreTransaction(request) {
    Sentry.addBreadcrumb({
      category: 'app-call',
      message: appCalls.RESTORE,
      data: {request},
      level: 'info'
    });
    UnityAppCall.send(appCalls.RESTORE, {request});
  }

  /**
   *
   * @param {Object} params
   */
  static playVideo(params) {
    Sentry.addBreadcrumb({
      category: 'app-call',
      message: appCalls.PLAY_VIDEO,
      data: params,
      level: 'info'
    });
    UnityAppCall.send(appCalls.PLAY_VIDEO, params);
  }

  /**
   *
   * @param {String} eventToken
   */
  static adjustEvent(eventToken) {
    if (Cookies.get(cookiesConsent.TARGETING) === 'false') {
      window.postMessage({
        request: appCallCallbacks.ADJUST_EVENT
      }, window.parent.origin);
    }

    const customParams = {
      device_id: Cookies.get(cookies.DEVICE_ID),
      app_session_id: Cookies.get(cookies.APP_SESSION_ID),
      user_uuid: userInfoSdo.userInfo.userUuid
    };

    Sentry.addBreadcrumb({
      category: 'app-call',
      message: appCalls.ADJUST_EVENT,
      data: {
        eventToken,
        customParameters: JSON.stringify(customParams)
      },
      level: 'info'
    });

    UnityAppCall.send(appCalls.ADJUST_EVENT, {
      eventToken,
      customParameters: JSON.stringify(customParams)
    });
  }

  /**
   *
   */
  static closeApp() {
    Sentry.addBreadcrumb({
      category: 'app-call',
      message: appCalls.CLOSE_APP,
      level: 'info'
    });
    UnityAppCall.send(appCalls.CLOSE_APP);
  }
}

export {
  AppCallService
};
