import {environmentTypeEnumerate} from '@aofl/server-environment';
import {environment as serverEnvironment} from '../server-environment';

let modalsConfig = {
  loaderIcon: {
    resolve: () => import('../../modules/loader-icon')
  },
  minDeviceReqModal: {
    resolve: () => import('../../modules/min-device-req-modal')
  },
  cookieConsentModal: {
    resolve: () => import('../../modules/cookie-consent-modal')
  },
  captchaModal: {
    resolve: () => {
      return `
      <div id="captcha-modal">
        <div id="captcha-modal-header" class="panel-header blue">Hello!</div>
        <div id="captcha-content" class="panel-content">
          <p id="verify">Please verify you are a human:</p>
          <div id="px-captcha"></div>
        </div>
      </div>
      `;
    }
  },
};

const debugMenu = {
  debugMenu: {
    resolve: () => import('../debug-menu')
  }
};

if (serverEnvironment === environmentTypeEnumerate.DEV || serverEnvironment === environmentTypeEnumerate.STAGE) {
  modalsConfig = Object.assign({}, debugMenu, modalsConfig);
}

export {
  modalsConfig
};
