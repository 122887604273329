/**
 * @summary sdo-product-group
 * @version 1.0.0
 * @since 1.0.0
 * @author Alex Dinari <alex.dinari@aofl.com>
 */
import {Sdo, storeInstance, state} from '@aofl/store';
import {CacheManager, cacheTypeEnumerate} from '@aofl/cache-manager';
import {deepAssign} from '@aofl/object-utils';
import {cacheNamespaces} from './constants-enumerate';

const standardRegPathProductGroupSessionData = new CacheManager(cacheNamespaces.PRODUCT_GROUP, cacheTypeEnumerate.LOCAL); /* eslint-disable-line */
const standardRegPathProductGroupData = standardRegPathProductGroupSessionData.getItem(cacheNamespaces.PRODUCT_GROUP); /* eslint-disable-line */
const nonStandardRegPathProductGroupSessionData = new CacheManager(cacheNamespaces.PRODUCT_GROUP, cacheTypeEnumerate.LOCAL); /* eslint-disable-line */
const nonStandardRegPathProductGroupData = nonStandardRegPathProductGroupSessionData.getItem(cacheNamespaces.PRODUCT_GROUP); /* eslint-disable-line */

/**
 * @type {String}
 * @private
 */
const NAMESPACE = 'product-group';

/**
 * @extends {Sdo}
 */
class ProductGroupSdo extends Sdo {
  /**
   * @static
   * @type {String}
   */
  static namespace = NAMESPACE;

  /**
   * @static
   * @type {Object}
   */
  @state()
  subscriptionInfo = {
    productGroupHash: standardRegPathProductGroupData?.subscriptionProductGroupHash ||
      nonStandardRegPathProductGroupData?.subscriptionProductGroupHash || '',
    price: standardRegPathProductGroupData?.subscriptionPrice ||
      nonStandardRegPathProductGroupData?.subscriptionPrice || '',
    initialPrice: standardRegPathProductGroupData?.subscriptionInitialPrice ||
      nonStandardRegPathProductGroupData?.subscriptionInitialPrice || '',
    initialPeriod: standardRegPathProductGroupData?.subscriptionInitialPeriod ||
      nonStandardRegPathProductGroupData?.subscriptionInitialPeriod || ''
  };

  /**
   * @static
   * @type {Object}
   */
  @state()
  extensionInfo = {
    productGroupHash: nonStandardRegPathProductGroupData?.extensionProductGroupHash || '',
    price: nonStandardRegPathProductGroupData?.extensionPrice || '',
  };

  /**
   * @static
   * @type {Object}
   */
  @state()
  upgradeInfo = {
    annual: {
      productGroupHash: standardRegPathProductGroupData?.annualProductGroupHash ||
        nonStandardRegPathProductGroupData?.annualProductGroupHash || '',
      productUuid: standardRegPathProductGroupData?.annualProductUuid ||
        nonStandardRegPathProductGroupData?.annualProductUuid || '',
      price: standardRegPathProductGroupData?.annualPrice ||
        nonStandardRegPathProductGroupData?.annualPrice || '',
      monthsDiscount: '',
      monthsDiscountText: '',
      percentDiscount: standardRegPathProductGroupData?.annualPercentDiscount ||
        nonStandardRegPathProductGroupData?.annualPercentDiscount || '',
      monthlyPrice: standardRegPathProductGroupData?.annualMonthlyPrice ||
        nonStandardRegPathProductGroupData?.annualMonthlyPrice || '',
      termLength: '',
      annualTermType: standardRegPathProductGroupData?.annualTermType ||
        nonStandardRegPathProductGroupData?.annualTermType || '',
      annualTermPeriod: standardRegPathProductGroupData?.annualTermPeriod ||
        nonStandardRegPathProductGroupData?.annualTermPeriod || '',
      annualMonthsDiscount: standardRegPathProductGroupData?.annualMonthDiscount ||
        nonStandardRegPathProductGroupData?.annualMonthDiscount || ''
    },
    semiAnnual: {
      productGroupHash: standardRegPathProductGroupData?.semiAnnualProductGroupHash || '',
      productUuid: standardRegPathProductGroupData?.semiAnnualProductUuid || '',
      price: standardRegPathProductGroupData?.semiAnnualPrice || '',
      monthsDiscount: '',
      monthsDiscountText: '',
      percentDiscount: standardRegPathProductGroupData?.semiAnnualPercentDiscount || '',
      monthlyPrice: standardRegPathProductGroupData?.semiAnnualMonthlyPrice || '',
      termLength: '',
      semiAnnualTermType: standardRegPathProductGroupData?.semiAnnualTermType || '',
      semiAnnualTermPeriod: standardRegPathProductGroupData?.semiAnnualTermPeriod || '',
      semiAnnualMonthsDiscount: standardRegPathProductGroupData?.semiAnnualMonthsDiscount || ''
    },
    specialOffer: {
      productGroupHash: nonStandardRegPathProductGroupData?.specialOfferProductGroupHash || '',
      productUuid: nonStandardRegPathProductGroupData?.specialOfferProductUuid || '',
      price: nonStandardRegPathProductGroupData?.specialOfferPrice || '',
      monthsDiscount: '',
      monthsDiscountText: '',
      percentDiscount: nonStandardRegPathProductGroupData?.specialOfferPercentDiscount || '',
      monthlyPrice: nonStandardRegPathProductGroupData?.specialOfferMonthlyPrice || '',
      termLength: '',
      annualTermType: nonStandardRegPathProductGroupData?.specialOfferTermType || '',
      annualTermPeriod: nonStandardRegPathProductGroupData?.specialOfferTermPeriod || '',
      specialOfferMonthsDiscount: nonStandardRegPathProductGroupData?.specialOfferMonthsDiscount || ''
    }
  };

  /**
   *
   * @param {String} annual
   */
  setAnnualProductInfo(annual) {
    this.upgradeInfo = deepAssign(this.upgradeInfo, '', {
      annual
    });
  }

  /**
   *
   * @param {String} semiAnnual
   */
  setSemiAnnualProductInfo(semiAnnual) {
    this.upgradeInfo = deepAssign(this.upgradeInfo, '', {
      semiAnnual
    });
  }

  /**
   *
   * @param {String} semiAnnual
   */
  setSpecialOfferProductInfo(specialOffer) {
    this.upgradeInfo = deepAssign(this.upgradeInfo, '', {
      specialOffer
    });
  }
}

/**
 * @type {ProductGroupSdo}
 */
const productGroupSdo = new ProductGroupSdo();
storeInstance.addState(productGroupSdo);

export {
  productGroupSdo
};
