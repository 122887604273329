import {Polyfill} from '@aofl/polyfill-service';
import polyfills from './__config/polyfills';
import * as Sentry from '@sentry/browser';
import {initConfig} from './__config/sentry-config';

Sentry.init(initConfig);

window.aofljsConfig = window.aofljsConfig || {};

Polyfill.loadAll(polyfills)
  .then(() => {
    import(/* webpackMode: "eager" */'./app');
  });
