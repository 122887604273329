import {template} from './template';
import styles from './template.css';
import {AoflElement, customElement, property} from '@aofl/element';
import {routerInstance} from '@aofl/router';
import {environment} from '../constants-enumerate';
import {AppCallService} from '../app-call-service';

const LEADING_SLASH_REGEX = /^\//;

/**
 *
 * @class LinkToElement
 * @extends {AoflElement}
 */
@customElement('link-to')
class LinkToElement extends AoflElement {
  /**
   *
   * Creates an instance of LinkToElement.
   */
  constructor() {
    super();
    this.publicPathRegex = new RegExp('^' + environment.PUBLIC_PATH);
    this.href = '';
    this.orientationchanged = false;

    window.addEventListener('orientationchange', () => {
      this.orientationchanged = true;
    });
  }

  /**
   * @readonly
   */
  static is = 'link-to';

  /**
   * @return {Object}
   */
  @property({type: String, reflect: true})
  href = '';
  @property({type: String})
  target = '';
  @property({type: Boolean})
  'tab-outline-blue' = false;
  @property({type: Boolean})
  'tab-outline-yellow' = false;

  /**
   *
   * @param {Event} e
   */
  clickHandler(e) {
    e.preventDefault();
    if (this.target) {
      if (environment.IN_APP) {
        const origin = window.location.origin;
        let url = origin + this.href;
        if (/https/.exec(this.href) !== null) {
          url = this.href;
        }

        AppCallService.navigate(url, this.target);
      } else {
        window.open(this.href, this.target);
      }
    } else if (this.orientationchanged) {
      window.location = this.href;
    } else {
      routerInstance.navigate(this.href, {});
    }
  }

  /**
   *
   * @private
   * @param {String} name
   * @param {*} oldValue
   * @param {*} newValue
   */
  attributeChangedCallback(name, oldValue, newValue) {
    super.attributeChangedCallback(name, oldValue, newValue);
    if (name === 'href' && /https/.exec(this.href) === null) {
      const href = this.href.replace(this.publicPathRegex, '').replace(LEADING_SLASH_REGEX, '');
      this.href = environment.PUBLIC_PATH + href;
    }
  }

  /**
   *
   */
  firstUpdated() {
    super.firstUpdated();

    if (this['tab-outline-blue']) {
      this.classList.add('tab-outline-blue');
    } else {
      this.classList.remove('tab-outline-blue');
    }

    if (this['tab-outline-yellow']) {
      this.classList.add('tab-outline-yellow');
    } else {
      this.classList.remove('tab-outline-yellow');
    }
  }

  /**
   *
   * @return {Object}
   */
  render() {
    if (location.pathname === this.href) {
      this.classList.add('active');
    } else {
      this.classList.remove('active');
    }

    return super.render(template, [styles]);
  }
}

export default LinkToElement;
