import '../../modules/authstate-context';
import {pages, cookies, privacyPolicySections} from '../../modules/constants-enumerate';
import {userInfoSdo} from '../../modules/sdo-user-info';
import '../../modules/dd-menu';
import '../../modules/dd-menu-button';
import '../../modules/logout-button';
import '../../modules/link-to-element';
import '../../modules/main-header';
import '../../modules/main-layout';
import '../../modules/main-footer';
import '../../modules/picture';
import Cookies from 'js-cookie';

export default (ctx, html) => html`
  <main-layout full-height>
    <main-header dom-scope="s+N7UpYi" slot="page-header">
      <dd-menu-button class="tab-outline-yellow dd-focus" slot="left" tabindex="0"></dd-menu-button>
      <dd-menu slot="left">
        <link-to dom-scope="56GjcUxy" class="link enteractive" href="${pages.HOME}" tab-outline-blue><span>Home</span></link-to>

        <authstate-context>
            <link-to dom-scope="fERheCQi" class="link enteractive" href="${pages.LOGIN}" tab-outline-blue><span>Log In</span></link-to>
        </authstate-context>

        <authstate-context>
          <link-to dom-scope="J44hUS41" class="link enteractive" href="${pages.SUBSCRIPTION}" tab-outline-blue><span>Sign Up</span></link-to>
        </authstate-context>
        <link-to dom-scope="omQhbjCW" class="link enteractive" href="${pages.TANDC}" target="_blank" rel="noopener noreferrer" tab-outline-blue><span>Terms &amp; Conditions</span></link-to>
        <link-to dom-scope="0eR3XK/N" class="link enteractive" href="${pages.PRIVACY_POLICY}" target="_blank" rel="noopener noreferrer" tab-outline-blue><span>Privacy Policy</span></link-to>
        <link-to dom-scope="iYSjlPyH" class="link enteractive ${userInfoSdo.userInfo.isLoggedIn ? '' : 'reset-dd-focus'}" href="${pages.CUSTOMER_SUPPORT}" target="_blank" rel="noopener noreferrer" tab-outline-blue><span>Customer Support</span></link-to>

        <authstate-context  show-when-loggedin>
          <logout-button dom-scope="rb33V8/U" class="link tab-outline-blue enteractive reset-dd-focus" role="navigation" aria-label="Log Out of Adventure Academy" tabindex="0"><span>Log Out</span></logout-button>
        </authstate-context>
      </dd-menu>

    <authstate-context  show-when-loggedin slot="right">
      <logout-button dom-scope="qowbQcFF" class="link button yellow tab-outline-yellow enteractive" role="navigation" aria-label="Log Out of Adventure Academy" tabindex="0">Log Out</logout-button>
    </authstate-context>

    <authstate-context slot="right">
      <link-to dom-scope="CrJ4uAJP" class="link button yellow" role="navigation" href="${pages.LOGIN}" aria-label="Log In to Adventure Academy" tab-outline-yellow>Log In</link-to>
    </authstate-context>

    <authstate-context slot="right">
      <link-to dom-scope="tFP9Xcv9" class="link button yellow" role="navigation" href="${pages.SUBSCRIPTION}" aria-label="Sign Up for Adventure Academy" tab-outline-yellow>Sign Up</link-to>
    </authstate-context>
  </main-header>
  <div class="page-content" slot="page-content">
    <h1 class="title">404 Error</h1>
    <aofl-img id="professor" src="/assets/routes/404/404-page-not-found-v1.png" width="788" height="782"></aofl-img>
    <link-to dom-scope="FN6ZKc2K" id="home-link" class="blue button enteractive" href="${pages.HOME}" tab-outline-blue><span>Home</span></link-to>
  </div>

  <main-footer dom-scope="6K1Pm4l4" slot="page-footer">
    <link-to class="link" dom-scope="L2BNnsxD" href="${pages.TANDC}" target="_blank" rel="noopener noreferrer" tab-outline-yellow>Terms &amp; Conditions</link-to>
    <span class="divider" aria-hidden="true">|</span>
    ${Cookies.get(cookies.REGION) === 'CA' ? html`
      <link-to dom-scope="rLI/PA6E" class="link" href="${privacyPolicySections.CALIFORNIA_PRIVACY_RIGHTS}" target="_blank" rel="noopener noreferrer" tab-outline-yellow>Privacy Policy</link-to>
      <span class="divider" aria-hidden="true">|</span>
    `: html`
      <link-to class="link" dom-scope="H4ZQAWiD" href="${pages.PRIVACY_POLICY}" target="_blank" rel="noopener noreferrer" tab-outline-yellow>Privacy Policy</link-to>
      <span class="divider" aria-hidden="true">|</span>
    `}
    ${Cookies.get(cookies.REGION) === 'CA' ? html`
      <a dom-scope="HsWpAFQP" class="link" @click="${(e) => ctx.showCookieConsentModal(e)}">Do Not Sell My Personal Information</a>
      <span class="divider" aria-hidden="true">|</span>
    ` : ''}
    <link-to dom-scope="hzZVEWPw" class="link" href="${pages.CUSTOMER_SUPPORT}" target="_blank" rel="noopener noreferrer" tab-outline-yellow>Customer Support</link-to>
  </main-footer>
</main-layout>
`;
